/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const ChoiceCardWrap = styled.div`
  width: 17.5rem;
  max-width: 100%;

  &:first-child {
    margin-right: 1rem;
  }

  .mastodon-choice-card {
    width: 100%;

    .cds--radio-button__appearance {
      margin-right: 0 !important;
    }
  }
`;
