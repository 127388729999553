import React, { useMemo } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useCandidateRededesignPhaseFour } from 'modules/candidate/utilities';
import RightPanel from './right-panel';

type Props = {};

const IconButton = styled(M.Button)`
  #mastodon && {
    border: none;
    align-self: start;
    svg {
      fill: rgba(26, 32, 38, 0.78);
    }
  }
`;

const ActionMenu = styled(M.ActionMenu)`
  #mastodon && {
    align-self: start;
    .mastodon-action-menu-container {
      z-index: 1000;
    }
    .mastodon-action-menu-children {
      padding: 1rem 0;
    }
  }
`;

const Settings: React.FC<Props> = () => {
  const rededesignPhaseFour = useCandidateRededesignPhaseFour();

  const trigger = useMemo(
    () => (
      <IconButton kind='tertiary'>
        <M.Icon icon='SettingsView' />
      </IconButton>
    ),
    [],
  );

  if (!rededesignPhaseFour) return null;

  return (
    <ActionMenu
      position='left-bottom'
      trigger={trigger}
      data-pendo-id='candidate-page-settings-menu'
    >
      <RightPanel />
    </ActionMenu>
  );
};

export default Settings;
