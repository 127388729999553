import React, { useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import moment from 'moment';
import { useReport } from 'containers/Report';
import { useAdverseActions } from 'modules/adjudication/api';
import { useGetReport } from 'api/reports';
import { getTimeDelta } from '@dashboard-experience/utils';
import { useTranslation } from 'react-i18next';
import { scrollToTop, useTrackEvent } from 'utils';
import { CANDIDATE_REPORT_EVENT_NAMES } from 'utils/analytics/events';
import { namespace } from 'modules/adjudication/locales';
import PostAdverseActionNoticeModal from 'components/AdverseActions/PostAdverseActionNoticeModal';

const usePostAdverseAction = () => {
  const { report } = useGetReport();
  const trackEvent = useTrackEvent();
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const { id: reportId } = useReport();
  const { adverseAction } = useAdverseActions(reportId);
  const container = (document.getElementById('mastodon') ||
    document.body) as Element;

  const action = useCallback(() => {
    setShowModal(true);
    trackEvent(
      CANDIDATE_REPORT_EVENT_NAMES.CUSTOMER_SELECTED_CANDIDATE_ACTION,
      { Action: 'Post Adverse Action' },
    );
    // TODO: remove once customer is standalone app
    scrollToTop();
  }, []);

  const closeModal = useCallback(() => setShowModal(false), []);

  const postDate = moment(adverseAction?.post_notice_ready_at);
  const postDateDelta = getTimeDelta(postDate, moment.now());
  const enablePostAA = postDateDelta.asMilliseconds() >= 0;

  if (!adverseAction || report.archived || !enablePostAA) {
    return null;
  }

  const title = t(`${namespace}:report.actions.post_adverse.action`);
  const subtitle = t(`${namespace}:report.actions.post_adverse.description`);

  const modal = showModal
    ? createPortal(
        <PostAdverseActionNoticeModal
          data-testid='post-aa-notice-modal'
          closeModal={closeModal}
          adverseAction={adverseAction}
        />,
        container,
      )
    : null;

  return {
    title,
    subtitle,
    action,
    modal,
  };
};

export default usePostAdverseAction;
