/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import { updateParentWindowUrl } from 'utils';
import BetterOrderExperienceContext from 'pages/BetterOrderExperience/context';
import UIContext from 'context/UI';
import { Footer, StepContainer } from '../ui';

const SelectPackageStep: React.FC<{}> = () => {
  const history = useHistory();
  // TODO: Update
  const disabled = false;
  const { contextId } = useContext(UIContext);
  const { infoSource } = useContext(BetterOrderExperienceContext);

  const handleBack = useCallback(() => {
    const path = '/order-experience/get-started';
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [contextId, history]);

  const handleContinue = useCallback(() => {
    const path = '/order-experience/add-ons';
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [contextId, history]);

  return (
    <StepContainer data-testid='select-package-step-container'>
      Select Package Placeholder
      {/* Uncomment to test; Remove below when no longer needed */}
      {/* <div>Example placeholder context: {infoSource}</div> */}
      <Footer>
        <M.Button
          type='button'
          data-testid='select-package-back-btn'
          onClick={handleBack}
        >
          Go back
        </M.Button>
        <M.Button
          type='button'
          data-testid='select-package-continue-btn'
          disabled={disabled}
          onClick={handleContinue}
        >
          Continue
        </M.Button>
      </Footer>
    </StepContainer>
  );
};

export default SelectPackageStep;
