import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import type { GetStartedT } from 'components/BetterOrderExperience';
import { namespace } from '../../locales';

type InfoMethodRadiosProps = {
  infoMethod: GetStartedT.InfoMethod;
  setSelectedInfoMethod: Dispatch<SetStateAction<GetStartedT.InfoMethod>>;
};

type InfoMethodRadioOption = {
  id: string;
  title: string;
  description: string;
  value: GetStartedT.InfoMethod;
};

const InfoMethodRadios = ({
  infoMethod,
  setSelectedInfoMethod,
}: InfoMethodRadiosProps) => {
  const { t } = useTranslation();
  const basePath = `${namespace}:candidateContactInfo.infoMethodRadios`;

  const options: InfoMethodRadioOption[] = [
    {
      id: 'manual',
      title: t(`${basePath}.manual.title`),
      description: t(`${basePath}.manual.description`),
      value: 'MANUAL',
    },
    {
      id: 'upload',
      title: t(`${basePath}.upload.title`),
      description: t(`${basePath}.upload.description`),
      value: 'UPLOAD',
    },
  ];

  return (
    <M.ChoiceCardGroup
      data-testid='info-method-radio-group'
      type='radio'
      name='info-method'
      defaultSelected={infoMethod}
      onChange={useCallback(
        (value: GetStartedT.InfoMethod) => setSelectedInfoMethod(value),
        [setSelectedInfoMethod],
      )}
      columns={{ l: 2, m: 2, s: 2 }}
      width='50%'
    >
      {options.map(choice => {
        const { id, title, description, value } = choice;
        return (
          <M.ChoiceCard
            key={id}
            data-testid={`info-method-radio-group-${id}`}
            title={title}
            description={description}
            value={value}
          />
        );
      })}
    </M.ChoiceCardGroup>
  );
};

export default InfoMethodRadios;
