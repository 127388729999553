import { M } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import {
  accountHasPermission,
  hasPermission,
} from '@dashboard-experience/utils';
import withIframeComponentWrapper from 'components/Report/iframeComponentWrapper';
import { useCandidate } from 'providers/Candidate';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Candidate, Report } from 'types';
import { scrollToElement, updateParentWindowUrl } from 'utils';

import {
  ENABLE_ARCHIVE_REPORTS,
  I9_ORDERING,
  ORDER_ADDONS_FLOW,
} from 'Constants';
import { useGetDashboardCandidateReports } from 'api/candidate/hooks';
import AddChecks from 'components/Packages/AddChecks';
import {
  ADD_CHECKS_EVENT_NAMES,
  useTrackEvent as useTrackAddChecksEvent,
} from 'components/Packages/Amplitude/analytics';
import { RealtimeContext } from 'containers/RealtimeProvider';
import { useUser } from 'context/CurrentUser';
import UIContext from 'context/UI';
import ViewerAvatars from 'modules/candidate/ui/header/viewers/ViewerAvatars';
import queryString from 'querystring';
import { CANDIDATE_REPORT_EVENT_NAMES, useTrackEvent } from 'utils/analytics';
import ReportActionLog from 'modules/candidate/ui/header/report-actions/report-action-log';
import { AddChecksButton } from '../../modules/candidate/ui/header/report-actions/add-checks';
import PageHeaderSingle from '../PageHeaderSingle';
import ReportDropdown from './ReportDropdown';
import ReportSelectionButtons from './ReportSelectionButtons';

const Row = styled(M.GridRow)`
  margin-bottom: 1.5rem;
`;

const ButtonsWrapper = styled.div<{ framewidth: number }>`
  #mastodon & {
    margin-top: 1.5rem;

    @media (min-width: ${props => props.framewidth}px) {
      flex-grow: 1;
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin-top: 0;
      order: 2;
    }

    @media (max-width: ${props => props.framewidth}px) {
      .mastodon-button.tertiary {
        &:not(:last-child) {
          margin-left: 0;
        }
      }
    }
  }
`;

const DropdownWrapper = styled.div<{ framewidth: number }>`
  margin-top: 1.5rem;
  max-width: 20.8rem;

  @media (max-width: ${props => props.framewidth}px) {
    min-width: 12.8rem;
  }

  @media (min-width: ${props => props.framewidth}px) {
    margin-top: 0;
  }
`;

const IconButton = styled(M.Button)`
  span {
    margin-left: 0.5rem;
  }
`;

type MatchParams = {
  candidateId: string;
  reportId: string;
  invitationId: string;
};

const getCandidateName = (candidate: Candidate) => {
  if (!candidate.first_name) return candidate.email || 'Unknown candidate';
  const name = [candidate.first_name];
  if (candidate.middle_name) name.push(candidate.middle_name);
  if (candidate.last_name) name.push(candidate.last_name);
  return name.join(' ');
};

const showLogLinkMsg = 'report log';

// NOTE: components/Candidate is in the process of being deprecated
// in favor of modules/candidate. While the migration is in process,
// updates have to be made to both locations. For guidance, please
// reach out at #prod-dashboard-reports-page
const PageHeader = () => {
  const [addCheckModalOpen, setAddCheckModalOpen] = useState(false);
  const candidate = useCandidate();
  const currentUser = useUser();
  const { isIframe } = useContext(UIContext);
  const { t } = useTranslation('', { keyPrefix: 'realtime.reload' });
  const canViewReportLogs = hasPermission(currentUser, 'read_report_logs');

  const name = getCandidateName(candidate);

  const archiveReportFlagEnabled =
    useFlag(ENABLE_ARCHIVE_REPORTS)?.variantKey === 'on';

  // A La Carte Order Addons Flow / Add Checks Flow
  const orderAddonsFlowEnabled =
    useFlag(ORDER_ADDONS_FLOW)?.variantKey === 'enabled';

  const { reports: candidateReports, isLoading } =
    useGetDashboardCandidateReports(candidate.id);

  const showI9Button = useFlag(I9_ORDERING)?.variantKey === 'on';

  // reportsAndInvitations
  const reportsAndInvitations = useMemo(() => {
    const invitations = candidate?.invitations?.filter(invitation => {
      return !['cancelled', 'completed', 'expired'].includes(invitation.status);
    });

    const reports = [
      ...(candidateReports || []).concat(...(invitations || [])),
    ].reverse();

    if (!archiveReportFlagEnabled) return reports;

    return reports.sort((a, b) => {
      // eslint-disable-next-line no-nested-ternary
      return a.archived === b.archived ? 0 : a.archived ? 1 : -1;
    });
  }, [candidate?.invitations, candidateReports, archiveReportFlagEnabled]);

  // Filter reports based on type
  function filterByType(reports: Report[], isArchived?: boolean) {
    if (isArchived === undefined) {
      return reports; // If isArchived is undefined, return all reports
    }
    return reports.filter(report => report.archived === isArchived);
  }

  // Generate report title
  function generateReportTitle(status: string) {
    return [{ id: `title-${status.toLowerCase()}`, status, disabled: true }];
  }

  const reportsActive = filterByType(reportsAndInvitations, false);
  const reportsArchived = filterByType(reportsAndInvitations, true);
  let reportsDropdown: any[];

  if (reportsArchived.length) {
    const reportsActiveTitle =
      reportsActive.length > 0 ? generateReportTitle('Active') : [];
    const reportsArchivedTitle =
      reportsArchived.length > 0 ? generateReportTitle('Archived') : [];

    reportsDropdown = [
      ...reportsActiveTitle,
      ...reportsActive,
      ...reportsArchivedTitle,
      ...reportsArchived,
    ];
  } else {
    reportsDropdown = reportsAndInvitations;
  }

  const { subscribeToRoom, roomViewers } = useContext(RealtimeContext);

  useEffect(() => {
    subscribeToRoom({
      roomID: candidate.id,
      roomType: 'candidate',
      toastMessage: t('updated_candidate', {
        count: reportsAndInvitations.length,
      }),
    });
  }, [candidate.id, reportsAndInvitations.length, subscribeToRoom, t]);

  const hasReports = !!reportsAndInvitations.length;

  // Figure out the currently selected report to mark it active
  const reportMatch = useRouteMatch<MatchParams>(
    '/candidates/:candidateId/reports/:reportId',
  );

  // Figure out the currently selected invitation to mark it active
  const invitationMatch = useRouteMatch<MatchParams>(
    '/candidates/:candidateId/invitations/:invitationId',
  );

  const invitationOrReportId =
    reportMatch?.params?.reportId || invitationMatch?.params?.invitationId;

  const selectedReport = useMemo(
    () =>
      reportsAndInvitations.find(report => report.id === invitationOrReportId),
    [invitationOrReportId, reportsAndInvitations],
  );

  // Update the url when selecting a new report
  const history = useHistory();
  const { contextId } = useContext(UIContext);

  const updateUrl = useCallback(
    (url, reportOrInvite) => {
      const path =
        reportOrInvite === 'report'
          ? `/candidates/${candidate.id}/reports/${url}`
          : `/candidates/${candidate.id}/invitations/${url}`;
      // if in iframe, update parent url as well when we switch reports
      if (contextId) {
        updateParentWindowUrl({
          contextId,
          path,
          reload: true,
        });
      }
      history.push(path);
    },
    [history, contextId, candidate],
  );

  // Dynamically show a dropdown when there are too many buttons to fit on the page
  const [showDropdown, setShowDropdown] = useState(false);
  const buttonContainerRef = useRef<HTMLDivElement>(null);

  // Calculate if we should show the dropdown if the buttons extend off the page
  const calculateDropdown = useCallback(() => {
    if (buttonContainerRef?.current) {
      const buttonContainer = buttonContainerRef.current;
      const rect = buttonContainer.getBoundingClientRect();
      const { right } = rect;
      if (right > (window.innerWidth || document.documentElement.clientWidth)) {
        setShowDropdown(true);
      } else {
        setShowDropdown(false);
      }
    }
  }, [setShowDropdown]);

  // When reports update, re-evaluate if we need to show the dropdown
  useEffect(() => {
    calculateDropdown();
  }, [reportsAndInvitations, calculateDropdown]);

  // When the window resizes, re-evaluate if we need to show the dropdown
  useEffect(() => {
    const resizeListener = () => {
      setShowDropdown(false);
      calculateDropdown();
    };
    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  }, [setShowDropdown, calculateDropdown]);

  const [showTimeline, setShowTimeline] = useState(false);

  const trackEvent = useTrackEvent();
  const trackAddChecksEvent = useTrackAddChecksEvent();

  const toggleTimeline = useCallback(() => {
    setShowTimeline(s => !s);

    if (currentUser) {
      trackEvent(CANDIDATE_REPORT_EVENT_NAMES.REPORT_VIEW_REPORT_LOG_USED, {
        'Show Or Hide': !showTimeline ? 'Show' : 'Hide',
      });
    }
  }, [showTimeline, currentUser, trackEvent]);

  useEffect(() => {
    setShowTimeline(false);
  }, [selectedReport]);

  const scrollToOrderSection = () => {
    const orderNewWrap = document.getElementById('order-new-wrapper');
    const orderNewElOffset = orderNewWrap?.offsetTop;

    if (orderNewElOffset) {
      window.scrollTo({
        top: orderNewElOffset,
        behavior: 'smooth',
      });
      scrollToElement(orderNewElOffset);
    }
  };

  const orderNewReport = useCallback(() => {
    if (currentUser) {
      trackEvent(
        CANDIDATE_REPORT_EVENT_NAMES.REPORT_VIEW_ORDER_NEW_REPORT_SELECTED,
        {
          Source: 'Page Header',
        },
      );
    }

    const path = `/candidates/${candidate.id}/new`;
    history.push(path);
    scrollToOrderSection();
  }, [currentUser, candidate.id, history, trackEvent]);

  const canOrderNewReport = // TODO - Should this be using the candidate's account info to determine permissions? (Migrated 1:1)
    hasPermission(currentUser, 'create_manual_orders') &&
    accountHasPermission(currentUser, 'allow_dashboard_report_ordering');

  const showAddChecksButton =
    canOrderNewReport && orderAddonsFlowEnabled && !!candidate?.last_report_id;
  const isInternationalCandidate = candidate.geos
    ? candidate.geos?.filter(
        geo => geo.country !== 'US' && geo.country !== null,
      )?.length > 0
    : false;

  const isCandidateEmailEmpty = !candidate?.email?.length;

  const addChecksToolTipLabel = useMemo(() => {
    if (isInternationalCandidate) {
      return 'This feature is not available for international candidates yet.';
    }
    if (isCandidateEmailEmpty) {
      return 'This feature is unavailable because the candidate requested removal of their personal information.';
    }
    return 'This feature is currently not available';
  }, [isInternationalCandidate, isCandidateEmailEmpty]);

  const openModal = useCallback(e => {
    trackAddChecksEvent(
      currentUser,
      ADD_CHECKS_EVENT_NAMES.ADD_CHECKS_SELECTED,
    );
    setAddCheckModalOpen(true);
  }, []);

  const title = {
    label: name,
  };

  const handleI9Click = useCallback(() => {
    const queryParams = { source: 'report' };
    const queryStringified = queryString.stringify(queryParams);
    const path: string = '/i-9';
    if (isIframe && contextId) {
      updateParentWindowUrl({
        contextId,
        path,
        reload: true,
      });
    } else {
      history.push({
        pathname: '/i-9',
        search: queryStringified,
      });
    }
  }, []);

  const headerNode = (
    <>
      {/* Dropdown shows to the right of name if the report buttons can't all fit */}
      {hasReports && showDropdown && (
        <DropdownWrapper framewidth={isIframe ? 720 : 1200}>
          <ReportDropdown
            reports={reportsDropdown}
            selectedReport={selectedReport}
            updateUrl={updateUrl}
          />
        </DropdownWrapper>
      )}
      <ButtonsWrapper framewidth={isIframe ? 720 : 1200}>
        {roomViewers.length > 0 && <ViewerAvatars viewers={roomViewers} />}
        {/* Report timeline and order new report go on the right */}
        {hasReports && selectedReport && canViewReportLogs && (
          <IconButton
            data-testid='show-report-log'
            kind='tertiary'
            size='small'
            onClick={toggleTimeline}
          >
            <M.Icon icon='List' />
            <span>
              {showTimeline
                ? `Hide ${showLogLinkMsg}`
                : `Show ${showLogLinkMsg}`}
            </span>
          </IconButton>
        )}
        {canOrderNewReport && !orderAddonsFlowEnabled && (
          <M.Button
            data-testid='order-new-report'
            kind='tertiary'
            size='small'
            onClick={orderNewReport}
          >
            Order new report
          </M.Button>
        )}
        {showAddChecksButton &&
          (isInternationalCandidate || isCandidateEmailEmpty) && (
            <M.Tooltip
              label={addChecksToolTipLabel}
              data-testid='add-checks-tooltip'
              align='bottom'
              style={{
                paddingTop: '2px',
              }}
            >
              <M.Icon icon='InformationFilled' />
            </M.Tooltip>
          )}
        {showAddChecksButton && !showI9Button && (
          <M.Button
            data-testid='add-checks-button'
            kind='secondary'
            size='small'
            onClick={openModal}
            disabled={isInternationalCandidate || isCandidateEmailEmpty}
          >
            Add checks
          </M.Button>
        )}

        {showAddChecksButton && showI9Button && (
          <AddChecksButton
            isDisabled={isInternationalCandidate || isCandidateEmailEmpty}
            addChecks={openModal}
            openI9={handleI9Click}
          />
        )}
      </ButtonsWrapper>
      {showAddChecksButton && (
        <AddChecks
          candidate={candidate}
          modalOpen={addCheckModalOpen}
          setModalOpen={setAddCheckModalOpen}
        />
      )}
    </>
  );

  const componentClassName = 'header-wrap-secondary';
  const titleTestId = 'candidate-report-title';

  return (
    <M.Grid>
      <PageHeaderSingle
        className={componentClassName}
        title={title}
        headerNode={headerNode}
        dataTestId={titleTestId}
      />
      {isLoading && (
        <M.LoadingBlock
          style={{ marginBottom: '1.5rem', width: '140px', height: '35px' }}
        />
      )}
      {/* Report buttons show below the name if they can fit */}
      {hasReports && !showDropdown && (
        <Row>
          <ReportSelectionButtons
            reports={reportsAndInvitations}
            selectedReport={selectedReport}
            updateUrl={updateUrl}
            ref={buttonContainerRef}
          />
        </Row>
      )}
      {showTimeline && <ReportActionLog />}
    </M.Grid>
  );
};

export default withIframeComponentWrapper(PageHeader, 'page_header');
