import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';

import {
  CANDIDATE_REPORT_EVENT_NAMES,
  scrollToTop,
  useTrackEvent,
} from 'utils';
import { useGetReport } from 'api/reports';

import { useRequestCandidateStory } from 'modules/adjudication/api';
import { namespace } from 'modules/adjudication/locales';
import { useDisplayRequestCandidateStory } from 'modules/adjudication/utilities';
import { ConfirmModal } from '../../../../../components';
import H3 from '../../common/h3';

const RequestCandidateStory = () => {
  const { report } = useGetReport();
  const { call, result } = useRequestCandidateStory();
  const trackEvent = useTrackEvent();

  const [modalOpen, setOpenModal] = useState(false);

  const openConfirmModal = useCallback(() => {
    setOpenModal(true);
    trackEvent(
      CANDIDATE_REPORT_EVENT_NAMES.CUSTOMER_SELECTED_CANDIDATE_ACTION,
      { Action: 'Request Candidate Story', Source: 'Decision Candidate Story' },
    );
    // TODO: remove once customer is standalone app
    scrollToTop();
  }, [setOpenModal]);

  const hideConfirmModal = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const handleConfirm = useCallback(() => {
    call();
    hideConfirmModal();
  }, [call, hideConfirmModal]);

  const { t } = useTranslation();
  const basePath = `${namespace}:report.actions.candidate_story`;
  const actionTitle = t(`${basePath}.action`);
  const actionDescription = t(`${basePath}.secondary-action-description`);
  const modalHeader = t(`${basePath}.modal.header`);
  const modalMessage = <H3>{t(`${basePath}.modal.msg`)}</H3>;

  const canRequestCandidateStory = useDisplayRequestCandidateStory();
  const requestDisabled =
    report?.candidate_stories?.length > 0 ||
    report?.tags?.includes('candidate-story-requested') ||
    (result.data && !result.isError) ||
    report.archived;

  if (!canRequestCandidateStory || requestDisabled) return null;

  return (
    <>
      <M.ActionMenuItem
        title={actionTitle}
        description={actionDescription}
        kind='node'
        actionNode={<M.Icon icon='RequestQuote' size='16' />}
        divider
        onClick={openConfirmModal}
      />
      {/* @ts-ignore */}
      <ConfirmModal
        header={modalHeader}
        open={modalOpen}
        hideModal={hideConfirmModal}
        callback={handleConfirm}
        message={modalMessage}
      />
    </>
  );
};

export default RequestCandidateStory;
