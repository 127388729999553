import { capitalize } from 'lodash';
import React, { useMemo } from 'react';
import { SectionProps } from '../types';
import { ExpandablePanel, KeyValueList } from '../styles';

const Nodes: React.FC<SectionProps> = ({ report }) => {
  const generateKeyValueItems = (segments: string[] = []) => {
    if (!segments.length) {
      return [];
    }
    return segments?.map(segment => {
      const [prop, value] = segment.split('|');
      return {
        itemKey: capitalize(prop),
        itemValue: value,
      };
    });
  };

  const items = useMemo(
    () => generateKeyValueItems(report.segment_stamps),
    [report.segment_stamps],
  );

  return (
    <ExpandablePanel
      initialExpanded
      data-testid='nodes-expandable-section'
      title='Nodes'
      style={{ marginTop: '2rem' }}
    >
      <KeyValueList items={items} />
    </ExpandablePanel>
  );
};

export default Nodes;
