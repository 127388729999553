/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import React, {
  useCallback,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import uniq from 'lodash/uniq';
import { useTranslation } from 'react-i18next';
import { Geo } from '@dashboard-experience/utils';
import { isInternationalCountry, updateParentWindowUrl } from 'utils';
import UIContext from 'context/UI';
import { useUser } from 'context/CurrentUser';
import BetterOrderExperienceContext from 'pages/BetterOrderExperience/context';
import AccountContext from 'pages/Account/AccountContext';
import { BillingEntity } from 'types/Billing';
import { Footer, StepContainer } from '../ui';
import { InfoSource } from './GetStartedStep.types';
import InfoSourceRadios from './InfoSourceSection/InfoSourceRadios';
import { ManualOrder } from './ManualOrderSection';
import { GetStartedT } from '..';
import {
  NodesAndWorkLocationSection,
  GeosAndProgramsSection,
} from './LocationSection';
import LocationSectionHeader from './LocationSection/LocationSectionHeader';
import { CandidateContactInfoSection } from './CandidateContactInfoSection';
import { BillingSection } from './BillingSection';
import { namespace } from '../locales';

const GetStartedStep: React.FC<{}> = () => {
  const { t } = useTranslation(namespace);
  const [selectedInfoSource, setSelectedInfoSource] = useState<
    InfoSource | undefined
  >();
  const [selectedManualOrderType, setSelectedManualOrderType] = useState<
    GetStartedT.ManualOrderType | undefined
  >();
  const [selectedGeo, setSelectedGeo] = useState<Geo>({} as Geo);
  const [selectedPaymentProfile, setSelectedPaymentProfile] =
    useState<BillingEntity>({} as BillingEntity);
  const [selectedLocation, setSelectedLocation] =
    useState<GetStartedT.WorkLocation>({} as GetStartedT.WorkLocation);
  const [selectedNode, setSelectedNode] = useState<GetStartedT.Node>();
  const [selectedProgram, setSelectedProgram] =
    useState<GetStartedT.Program | null>(null);
  const [enteredInvites, setEnteredInvites] = useState<GetStartedT.Invite[]>(
    [],
  );

  // TODO: Update disabled logic
  const disabled = false;
  const history = useHistory();

  const { contextId } = useContext(UIContext);
  const currentUser = useUser();
  const {
    update,
    basePackage,
    infoSource,
    manualOrderType,
    geo,
    location,
    node,
    program,
    invites,
    paymentProfile,
  } = useContext(BetterOrderExperienceContext);
  const {
    account,
    geos,
    nodes,
    nodesIsLoading,
    handleNodeSearchTextChange,
    nodeSearchText,
    programs,
    payment_profiles,
    billing_entity_enabled,
  } = useContext(AccountContext);
  const {
    segmentation_enabled,
    hierarchy_present,
    geos_required,
    programs_enabled,
    manual_orders_review,
  } = account;
  const countries = useMemo(() => {
    return uniq(geos.filter(geo => geo.country).map(geo => geo.country));
  }, [geos]);

  const isInternational = useMemo(() => {
    const country = segmentation_enabled
      ? selectedLocation?.country
      : selectedGeo?.country;

    return isInternationalCountry(country);
  }, [segmentation_enabled, selectedGeo?.country, selectedLocation?.country]);

  const handleContinue = useCallback(() => {
    const path = '/order-experience/select-your-package';
    update({
      infoSource: selectedInfoSource,
      location: selectedLocation,
      node: selectedNode,
      geo: selectedGeo,
      manualOrderType: selectedManualOrderType,
      invites: enteredInvites,
      paymentProfile: selectedPaymentProfile,
    });

    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [
    update,
    selectedInfoSource,
    selectedLocation,
    selectedNode,
    selectedGeo,
    selectedManualOrderType,
    enteredInvites,
    selectedPaymentProfile,
    contextId,
    history,
  ]);

  useEffect(() => {
    // The following persists the values on refresh & when going back and forth from steps
    setSelectedInfoSource(infoSource);
    setSelectedLocation(location);
    setSelectedNode(node);
    setSelectedGeo(geo);
    setSelectedProgram(program);
    setSelectedManualOrderType(manualOrderType);
    setEnteredInvites(invites);
    setSelectedPaymentProfile(paymentProfile);
  }, [
    geo,
    infoSource,
    location,
    manualOrderType,
    node,
    program,
    paymentProfile,
    invites,
    setSelectedInfoSource,
    setSelectedGeo,
    setSelectedManualOrderType,
    setEnteredInvites,
  ]);

  return (
    <StepContainer data-testid='get-started-step-container'>
      {billing_entity_enabled && (
        <BillingSection
          payment_profiles={payment_profiles}
          selectedPaymentProfile={selectedPaymentProfile}
          setSelectedPaymentProfile={setSelectedPaymentProfile}
        />
      )}
      {geos.length > 0 && <LocationSectionHeader />}
      {segmentation_enabled ? (
        <NodesAndWorkLocationSection
          countries={countries as string[]}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          nodes={nodes}
          nodesIsLoading={nodesIsLoading}
          selectedNode={selectedNode}
          setSelectedNode={setSelectedNode}
          nodeSearchText={nodeSearchText}
          handleNodeSearchTextChange={handleNodeSearchTextChange}
        />
      ) : (
        <GeosAndProgramsSection
          geos={geos}
          selectedGeo={selectedGeo}
          setSelectedGeo={setSelectedGeo}
          programs={programs}
          selectedProgram={selectedProgram}
          setSelectedProgram={setSelectedProgram}
          geos_required={geos_required}
          programs_enabled={programs_enabled}
        />
      )}

      <p className='h4'>{t('candidateDetails.header')}</p>

      <InfoSourceRadios
        isInternational={isInternational}
        manual_orders_review={manual_orders_review}
        selectedInfoSource={selectedInfoSource}
        setSelectedInfoSource={setSelectedInfoSource}
        accountId={JSON.stringify(account.id)}
      />

      {selectedInfoSource === 'CANDIDATE' && (
        <CandidateContactInfoSection
          enteredInvites={enteredInvites}
          setEnteredInvites={setEnteredInvites}
        />
      )}
      {selectedInfoSource === 'MYSELF' && (
        <ManualOrder
          currentUser={currentUser}
          selectedManualOrderType={selectedManualOrderType}
          setSelectedManualOrderType={setSelectedManualOrderType}
        />
      )}

      <Footer>
        <M.Button
          data-testid='get-started-continue'
          type='button'
          disabled={disabled}
          onClick={handleContinue}
        >
          Continue
        </M.Button>
      </Footer>
    </StepContainer>
  );
};

export default GetStartedStep;
