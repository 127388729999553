import {
  accountHasPermission,
  ADJUDICATION_VALUES,
  getResultOrStatus,
  hasPermission,
  isInternal,
  ReportStatuses,
} from '@dashboard-experience/utils';
import {
  CRIM_SCREENINGS,
  HEALTH_SCREENINGS,
  SCREENING_NAME_MAP,
} from 'Constants';
import { useGetReport } from 'api/reports';
import { useUser } from 'context/CurrentUser';
import { isEmpty } from 'lodash';
import pluralize from 'pluralize';
import { Report } from 'types';

export const useEnableCompleteNow = () => {
  // TODO: remove hard typing once Report type is updated in utils
  const { report } = useGetReport() as unknown as Report;
  return !accountHasPermission(
    report,
    'translate_canceled_status_to_suspended',
  );
};

export const useAllowCompleteNow = () => {
  const currentUser = useUser();
  const enableCompleteNow = useEnableCompleteNow();
  return (
    hasPermission(currentUser, `perform_complete_now`) &&
    !isInternal(currentUser) &&
    enableCompleteNow
  );
};

export const useShowCompleteNow = () => {
  const { report } = useGetReport();
  const status = report.status || '';
  const allowCompleteNow = useAllowCompleteNow();

  return ['pending', 'suspended'].includes(status) && allowCompleteNow;
};

export const useDisplayRequestCandidateStory = () => {
  const currentUser = useUser();
  // TODO: remove hard typing once Report type is updated in utils
  const { report } = useGetReport() as unknown as Report;
  const adjudicationChangeAllowed = hasPermission(
    currentUser,
    'perform_adjudication',
  );

  const reportCandidateStories = report?.account?.candidate_stories;

  const reportScreeningCount = Object.keys(SCREENING_NAME_MAP).filter(
    (screeningName) => {
      return (
        !isEmpty(report[screeningName]) ||
        !isEmpty(report[pluralize(screeningName)])
      );
    },
  ).length;

  const healthScreeningsCount = HEALTH_SCREENINGS.filter(
    (screeningName) => report[screeningName],
  ).length;

  const hasCrimScreeningsCount = CRIM_SCREENINGS.filter(
    (screeningName) => report[screeningName],
  ).length;

  // Verify whether the report exclusively contains health screenings
  // and has at least one criminal screening
  const hasValidScreenings =
    reportScreeningCount > healthScreeningsCount && hasCrimScreeningsCount > 0;

  const hasConsiderStatus =
    getResultOrStatus(report) === ReportStatuses.CONSIDER;

  const isNotPostAdverseAction =
    report.adjudication !== ADJUDICATION_VALUES.POST_ADVERSE_ACTION;

  const candidateStoriesAfterAdverseAction = accountHasPermission(
    report,
    'candidate_stories_after_adverse_action',
  );

  return (
    adjudicationChangeAllowed &&
    reportCandidateStories &&
    hasConsiderStatus &&
    hasValidScreenings &&
    (isNotPostAdverseAction || candidateStoriesAfterAdverseAction)
  );
};
