import { Tabs } from 'components/DashboardWrapper/tabs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SubmenuItem from './submenu-item';
import NavElement from './nav-element';
import { FullWidthActionMenu } from './styles';

type Props = {
  tabName: string;
  navCollapsed: boolean;
  submenuTabs: Tabs;
};

const NavElementWithSubmenu: React.FC<Props> = ({
  tabName,
  navCollapsed,
  submenuTabs,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'navigation' });

  const menuLabelText = t(tabName);

  return (
    <FullWidthActionMenu
      trigger={
        <NavElement
          navCollapsed={navCollapsed}
          text={menuLabelText}
          tabName={tabName}
        />
      }
      position='right-top'
      positionMenuAbsolutely
    >
      {Object.entries(submenuTabs).map(([titleKey, tab]) => {
        return <SubmenuItem key={titleKey} titleKey={titleKey} tab={tab} />;
      })}
    </FullWidthActionMenu>
  );
};

export default NavElementWithSubmenu;
