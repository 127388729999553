import React from 'react';
import { useTranslation } from 'react-i18next';
import { GetStartedE } from 'components/BetterOrderExperience';
import {
  ChoiceCardWithBadge,
  Tooltip,
  OpenManualOrderModalButton,
} from './InfoSourceRadioMyself.styles';
import { namespace } from '../../locales';
import { ChoiceCardWrap } from '../GetStartedStep.styles';

type Props = {
  isInternational: boolean;
  manual_orders_review: GetStartedE.ManualOrdersReview;
  handleModalOpen: () => void;
  onClick: (value: string) => void;
  checked: boolean;
};

const InfoSourceRadioMyself: React.FC<Props> = ({
  isInternational = false,
  manual_orders_review,
  handleModalOpen,
  onClick,
  checked,
}) => {
  const { t } = useTranslation('better_order_experience');
  const infoSourceBase = `${namespace}:candidateDetails.infoSourceRadios`;
  const disabled =
    isInternational ||
    manual_orders_review === GetStartedE.ManualOrdersReview.REQUESTED;
  const showModal =
    manual_orders_review === GetStartedE.ManualOrdersReview.REQUESTED ||
    manual_orders_review === GetStartedE.ManualOrdersReview.NOT_REQUESTED;

  const content = (
    <ChoiceCardWrap>
      <ChoiceCardWithBadge
        data-testid='info-source-radio-button-manual-orders'
        key='myself'
        value='MYSELF'
        name='MYSELF'
        title={t(`${infoSourceBase}.myself.title`)}
        description={t(`${infoSourceBase}.myself.description`)}
        onClick={onClick}
        checked={checked}
        disabled={disabled}
        className={manual_orders_review}
      />
    </ChoiceCardWrap>
  );

  if (isInternational) {
    return (
      <Tooltip
        data-testid='manual-order-international-tooltip'
        label={t('getStarted.manualOrders.tooltip')}
        align='top-right'
      >
        {content}
      </Tooltip>
    );
  }

  if (showModal) {
    return (
      <OpenManualOrderModalButton
        data-testid='manual-order-modal-wrap'
        onClick={handleModalOpen}
      >
        {content}
      </OpenManualOrderModalButton>
    );
  }

  return <>{content}</>;
};

export default InfoSourceRadioMyself;
