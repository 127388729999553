import { v4 as uuidv4 } from 'uuid';
import { Invite } from '../GetStartedStep';

export const emptyInvite: () => Invite = () => {
  return {
    id: uuidv4(),
    email: { value: '', isValid: true },
    firstName: { value: '', isValid: true },
    phoneNumber: { value: '', isValid: true },
  };
};

export const validateFirstName = (
  name: string,
  isOptional: boolean = false,
): boolean => {
  if (isOptional && !name) return true;

  const regex = /^[a-zA-Z0-9ªµºÀ-ÖØ-öø-ÿ\-'.,]+$/;
  return regex.test(name);
};

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const consecutiveDotRegex = /\.\./;

  if (!emailRegex.test(email) || consecutiveDotRegex.test(email)) {
    return false;
  }

  return true;
};

export const validatePhoneNumber = (
  phoneNumber: string,
  isOptional: boolean = false,
): boolean => {
  if (isOptional && !phoneNumber) return true;

  const phoneNumberRegex = /^[\d()-\s]+$/;
  return (
    phoneNumberRegex.test(phoneNumber) &&
    phoneNumber.length >= 10 &&
    phoneNumber.length <= 15
  );
};
