import React, { useCallback, useContext } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { FlagrContext } from '@dashboard-experience/react-flagr';
import { useTrackEvent } from 'utils';
import { Tab } from 'components/DashboardWrapper/tabs';
import { useUser } from 'context/CurrentUser';
import { ExternalLink } from './icons';

type Props = {
  titleKey: string;
  tab: Tab;
};

const SubmenuItem: React.FC<Props> = ({ titleKey, tab }) => {
  const user = useUser();
  const { flags } = useContext(FlagrContext) as any;
  const { t } = useTranslation('', { keyPrefix: 'navigation' });
  const trackEvent = useTrackEvent();

  const url = typeof tab.url === 'function' ? tab.url(user, flags) : tab.url;

  const trackingEventName = tab.tracking?.(user, flags);

  const clickHandler = useCallback(() => {
    // If this item has a defined tracking event associated with it, then trigger that event onclick
    if (trackingEventName) {
      trackEvent(trackingEventName);
    }
    window.open(url);
  }, [trackEvent, trackingEventName, url]);

  // Don't show the item if the user doesn't have the proper permissions/flags for it
  if (!tab.authorized(user, flags)) {
    return null;
  }

  return (
    <M.ActionMenuItem
      key={titleKey}
      title={t(titleKey)}
      onClick={clickHandler}
      id={`${titleKey}_left`}
      kind='node'
      actionNode={<ExternalLink />}
    />
  );
};

export default SubmenuItem;
