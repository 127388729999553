import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { M } from '@dashboard-experience/mastodon';
import { DEFAULT_USER_PREFERENCES } from 'Constants';
import {
  deduplicateCommunicatorEvents,
  TimelineEvent,
  TimelineFilters,
  TimelineProps,
  TimelineRow,
} from 'components/Timeline';
import { usePreference } from 'api/dashboardPreferences';
import { useFetchTimelines, useFetchEvents } from 'api/timeline';
import { ErrorText } from '../Text';

const Timeline: React.FC<TimelineProps> = ({ contextName, contextId }) => {
  const { isError, isLoading, timeline } = useFetchTimelines(
    contextName,
    contextId,
  );

  const adverseActionEvents = useFetchEvents();

  let dedupedTimeline: TimelineEvent[] = [];

  if (!isLoading && timeline && timeline.length && !dedupedTimeline.length) {
    dedupedTimeline = deduplicateCommunicatorEvents(timeline);
  }

  // Filters
  const [filterItems, setFilterItems] = usePreference('timelineFilterItems');

  useEffect(() => {
    if (filterItems.length < 5) {
      setFilterItems(DEFAULT_USER_PREFERENCES.timelineFilterItems);
    }
  }, []);

  /**
   * @name shouldShow
   * @function
   * @memberOf Timeline
   * @description Determines if a given event should be shown
   * @returns {boolean}
   * @param {object} event - The timeline event
   */
  const shouldShow = (event: TimelineEvent) =>
    filterItems?.some((item: any) => {
      return item.keys.includes(event.type) && item.show;
    });

  /**
   * @name updateFilters
   * @function
   * @memberOf Timeline
   * @description Manages state of the timeline filters
   * @returns {array} - The updated filters state
   * @param {array} - The current filters state
   * @param {integer} itemIndex - The index of the filter item
   * @param {boolean} itemValue - The value of the filter item, e.g. false is off, true is on
   */
  const updateFilters = useCallback(
    (filterItems, itemIndex, itemValue) => {
      // update the state
      const updatedFilterItems = [...filterItems];
      updatedFilterItems[itemIndex].show = itemValue;
      setFilterItems(updatedFilterItems);
    },
    [setFilterItems],
  );

  if (isLoading) {
    return <M.LoadingInline description='Loading report log...' />;
  }

  if (isError) {
    return (
      <ErrorText>
        <em>The report log has encountered an error.</em>
      </ErrorText>
    );
  }

  if (!dedupedTimeline.length) {
    return <em>No timeline events available.</em>;
  }

  const orderedTimeline = _.sortBy(
    [...dedupedTimeline, ...adverseActionEvents],
    ['time'],
  ).reverse();

  return (
    <>
      <TimelineFilters
        filterItems={filterItems}
        updateFilters={updateFilters}
      />
      <M.Table>
        <M.TableBody>
          {orderedTimeline.map((event, idx) =>
            shouldShow(event) ? (
              /* eslint-disable-next-line react/no-array-index-key */
              <TimelineRow timelineEvent={event} key={`event-${idx}`} />
            ) : null,
          )}
        </M.TableBody>
      </M.Table>
    </>
  );
};

export default Timeline;
