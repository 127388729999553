import React, { useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  NodesDropdown,
  CountryDropdown,
  StateDropdown,
  CityDropdown,
} from '../Dropdowns';
import type { GetStartedT } from '../..';

type Props = {
  countries: string[];
  selectedLocation: GetStartedT.WorkLocation;
  setSelectedLocation: (location: GetStartedT.WorkLocation) => void;
  nodes: GetStartedT.Node[];
  nodesIsLoading: boolean;
  selectedNode?: GetStartedT.Node;
  setSelectedNode: (node: GetStartedT.Node) => void;
  handleNodeSearchTextChange: (searchText: string) => void;
  nodeSearchText: string;
};

const NodesAndWorkLocationSection: React.FC<Props> = ({
  countries,
  selectedLocation,
  setSelectedLocation,
  nodes,
  nodesIsLoading,
  selectedNode,
  setSelectedNode,
  handleNodeSearchTextChange,
  nodeSearchText,
}) => {
  // Disable the nodes dropdown if there is only one node
  // (This will also be autoselected in the useEffect below)
  const nodesDisabled = nodes.length === 1 && nodeSearchText === '';
  const nodesInvalid =
    !nodesIsLoading && nodeSearchText !== '' && nodes.length === 0;

  const showCountryDropdown = countries.length > 0;
  const countryAndStateDisabled = nodes && !selectedNode;
  const showStateDropdown = selectedLocation?.country === 'US';
  const showCityDropdown = selectedLocation?.state;

  useEffect(() => {
    // If account has only one node, auto select it
    if (nodes.length === 1) {
      setSelectedNode(nodes[0]);
    }
  }, [nodes, setSelectedNode]);

  return (
    <M.Grid style={{ margin: 0 }} data-testid='nodes-and-work-location-section'>
      <M.GridRow style={{ margin: 0 }}>
        {nodes && (
          <NodesDropdown
            nodes={nodes}
            handleNodeFilter={handleNodeSearchTextChange}
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            setSelectedLocation={setSelectedLocation}
            isLoading={nodesIsLoading}
            invalid={nodesInvalid}
            disabled={nodesDisabled}
          />
        )}
        {showCountryDropdown && (
          <CountryDropdown
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            countries={countries.length > 0 ? (countries as string[]) : ['US']}
            disabled={countryAndStateDisabled}
          />
        )}
      </M.GridRow>

      <M.GridRow>
        {showStateDropdown && (
          <StateDropdown
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            disabled={countryAndStateDisabled}
          />
        )}
        {showCityDropdown && (
          <CityDropdown
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
          />
        )}
      </M.GridRow>
    </M.Grid>
  );
};

export default NodesAndWorkLocationSection;
