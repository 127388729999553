import React, { useCallback, useMemo } from 'react';
import { Geo } from '@dashboard-experience/utils';
import { GetStartedT } from 'components/BetterOrderExperience';
import { ProgramDropdown } from './Dropdown.styles';

const defaultItemToString = (item: any) => item?.name;

type Props = {
  programs: GetStartedT.Program[];
  selectedProgram: GetStartedT.Program | null;
  setSelectedProgram: (program: GetStartedT.Program) => void;
  selectedGeo: Geo;
};

const ProgramsDropdown: React.FC<Props> = ({
  programs = [],
  selectedProgram,
  setSelectedProgram,
  selectedGeo,
}) => {
  const selectablePrograms = useMemo(() => {
    // If no geos are available or no geos are selected, return all programs
    if (selectedGeo.name === 'None') {
      return programs;
    }
    // Else show programs for the currently selected geo
    return programs.filter(program => {
      return program.geo_ids.includes(selectedGeo.id);
    });
  }, [programs, selectedGeo.id, selectedGeo.name]);

  const handleProgramChange = useCallback(
    ({ selectedItem }) => {
      setSelectedProgram(selectedItem);
    },
    [setSelectedProgram],
  );

  return (
    <ProgramDropdown
      data-testid='programs-dropdown'
      id='programs-dropdown'
      titleText=''
      label='Programs'
      invalidText='Program not found'
      items={selectablePrograms}
      selectedItem={selectedProgram}
      onChange={handleProgramChange}
      itemToString={defaultItemToString}
    />
  );
};

export default ProgramsDropdown;
