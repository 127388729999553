import { M } from '@dashboard-experience/mastodon';
import { namespace } from 'modules/candidate/locales';
import { useCandidate } from 'providers/Candidate';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Exception } from 'types';
import {
  getExceptionDocumentTypes,
  getOldestExceptionCreationDate,
  getExpirationDaysMessage,
} from '../utils';

const List = styled.ul`
  line-height: 20px;
`;

const ListItem = styled.li`
  list-style-type: disc;
  margin-left: 25px;
  padding-left: 3px;
`;

const StyledLink = styled(M.Link)`
  display: inline !important;
`;

const MultipleExceptionsBanner = ({
  exceptions,
}: {
  exceptions: Exception[];
}) => {
  const candidate = useCandidate();
  const { t } = useTranslation();

  if (exceptions.length <= 1) return null;
  const expirationDaysMessage = getExpirationDaysMessage(exceptions);

  const translatedTitle = t(
    `${namespace}:report.exceptions.banners.multipleTitle`,
  );

  const translatedBodyIntro = t(
    `${namespace}:report.exceptions.banners.multipleBody`,
    {
      firstName: candidate.first_name,
    },
  );

  return (
    <M.ExpandableNotification
      key='exceptions'
      title={translatedTitle}
      secondaryTitle={getOldestExceptionCreationDate(exceptions)}
      bodyNode={
        <>
          <p>{translatedBodyIntro}</p>
          <List>
            {exceptions.map(exception => (
              <ListItem key={exception.id}>
                {t(`${namespace}:report.exceptions.banners.documentLine`, {
                  documentType: getExceptionDocumentTypes(exception),
                })}
                <StyledLink href={exception.verification_uri}>
                  {t(`${namespace}:report.exceptions.banners.showRequest`)}
                </StyledLink>{' '}
                {t(`${namespace}:report.exceptions.banners.sentToCandidate`)}
                <br />
              </ListItem>
            ))}
            <Trans
              t={t}
              i18nKey={`${namespace}:report.exceptions.banners.footer`}
              components={[<br key='1' />]}
              values={{
                firstName: candidate?.first_name || 'the candidate',
                expirationDaysMessage,
              }}
            />
          </List>
        </>
      }
      kind='warning'
      initialExpanded
    />
  );
};

export default MultipleExceptionsBanner;
