import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

interface ShowLinkModalProps {
  isOpen: boolean;
  onClose: () => void;
  packageName: string;
  applyFlowUrl: string | undefined;
}

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    &::before {
      content: '\\00D7';
    }
    aria-label {
      content: 'Close Modal';
    }
  }
`;

const ModalBody = styled.div`
  p {
    margin-bottom: 1rem;
  }
`;

const InlineNotificationSuccess = styled(M.InlineNotification)`
  margin-top: 1rem;
`;

const ShowLinkModal: React.FC<ShowLinkModalProps> = ({
  isOpen,
  onClose,
  packageName,
  applyFlowUrl,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleNotificationClose = useCallback(() => {
    setIsCopied(false);
  }, []);

  const handleCopy = useCallback((element: HTMLElement) => {
    navigator.clipboard.writeText(element.innerText.replace(/&amp;/g, '&'));
    setIsCopied(true);
  }, []);

  return (
    <M.ComposedModal open={isOpen}>
      <M.ModalHeader closeModal={onClose}>
        <ModalHeader>
          <h2>Copy link</h2>
        </ModalHeader>
      </M.ModalHeader>
      <M.ModalBody>
        <ModalBody>
          <p>
            Use the link below to schedule a post-accident or reasonable
            suspicion test. Your monthly invoice will show only completed tests.
          </p>
          {isCopied && (
            <InlineNotificationSuccess
              kind='success'
              title=''
              onCloseButtonClick={handleNotificationClose}
            >
              Link copied
            </InlineNotificationSuccess>
          )}
          <br />
          <div>{packageName}</div>
          <div data-testid='copy-field-container'>
            <M.CopyField
              text={applyFlowUrl}
              data-testid='copy-invitation-link'
              copy={handleCopy}
              style={{ maxWidth: '45rem' }}
            />
          </div>
          <br />
          <p>
            By clicking above, I certify that: (1) I have reviewed and approved
            of all documents that will be presented to the candidates; (2) I
            have a permissible purpose to request a consumer report on the
            candidates; and (3) my use of consumer reports will comply with all
            applicable laws.
          </p>
        </ModalBody>
      </M.ModalBody>
      <M.ModalFooter>
        <M.Button onClick={onClose}>Close</M.Button>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default ShowLinkModal;
