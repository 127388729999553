import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import { updateParentWindowUrl } from 'utils';
import UIContext from 'context/UI';
import { Footer, StepContainer } from '../ui';

const ReviewAndSubmitStep: React.FC<{}> = () => {
  // TODO: Update disabled
  const disabled = false;
  const history = useHistory();
  const { contextId } = useContext(UIContext);

  const handleBack = useCallback(() => {
    const addonsAvailable = true;
    const path = addonsAvailable
      ? '/order-experience/add-ons'
      : '/order/select-your-package';
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [contextId, history]);

  const submitOrder = useCallback(() => {
    console.log('TODO: Submit order');
  }, []);

  return (
    <StepContainer data-testid='review-and-submit-step-container'>
      Review and Submit Placeholder
      <Footer>
        <M.Button
          data-testid='review-step-back-btn'
          type='button'
          onClick={handleBack}
        >
          Go back
        </M.Button>
        <M.Button type='button' disabled={disabled} onClick={submitOrder}>
          Submit Order
        </M.Button>
      </Footer>
    </StepContainer>
  );
};

export default ReviewAndSubmitStep;
