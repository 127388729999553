import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

const RadioButtonWidth = styled(M.ChoiceCard)`
  border: 1px solid
    ${props => (props.checked ? colors.brandNavy2 : colors.brandSlate3)};
`;

export const ChoiceCardWithBadge = styled(RadioButtonWidth)`
  .mastodon-choice-card-title {
    &::after {
      display: inline-block;
      border-radius: 0.25rem;
      padding: 0.125rem 0.5rem;
      color: ${colors.uiTextPrimaryLight};
      font-weight: 400;
      font-size: 0.75rem;
      margin: -0.0625rem 0 -0.0625rem 0.5rem;
      line-height: 1rem;
    }
  }

  &.not_requested {
    pointer-events: none;
    .mastodon-choice-card-title {
      &::after {
        content: 'Pending setup';
        background: ${colors.uiYellow200};
      }
    }
  }

  &.requested {
    pointer-events: none;
    .mastodon-choice-card-title {
      &::after {
        content: 'Pending approval';
        background: ${colors.uiGrey200};
      }
    }
  }
`;

export const Tooltip = styled(M.Tooltip)`
  border-bottom: none !important;
`;

export const OpenManualOrderModalButton = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-align: left;
`;
