import * as yup from 'yup';

import { statesWithCities } from 'components/Signup/constants/complianceGeos';

const CITY_REGEX = /^[a-zA-Z\s'.,-]*$/;
const NAME_REGEX = /^[\p{L}\d\s'.,-]*$/u;
const EMAIL_REGEX =
  /^[\w+.-]+(\.[\w+.-]+)*@[a-zA-Z\d-]+(\.[a-zA-Z\d-]+)*\.[a-zA-Z]{2,}$/i;
const COMPANY_REGEX =
  /^[a-zA-Z0-9!#$%&',()*?^_\s`|~:][a-zA-Z0-9!#$%&'.,/()*+\-?^_\s`|~@:]*$/;
const PHONE_REGEX = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
const STREET_REGEX = /^[a-zA-Z0-9#.,'\-/|\s]*$/;
const TID_REGEX = /^(\d{2}-\d{7})$/;
const ZIPCODE_REGEX = /^\d{5}(-\d{4})?$/;

const Schema = yup.object().shape({
  fullName: yup
    .string()
    .matches(NAME_REGEX, 'Full name contains invalid characters')
    .required('Full name cannot be blank'),
  email: yup
    .string()
    .email('Email must be a valid email')
    .matches(EMAIL_REGEX, 'Email must be a valid email')
    .required('Business email cannot be blank'),
  password: yup
    .string()
    .required('Password cannot be blank')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(
      /[!@#$%^&*]/,
      'Password must contain at least one special character: !@#$%^&*.',
    )
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter.')
    .matches(/\d/, 'Password must contain at least one number.'),
  phone: yup
    .string()
    .required('Phone number cannot be blank')
    .matches(PHONE_REGEX, 'Invalid phone number'),
  company: yup
    .string()
    .required('Business name cannot be blank')
    .min(2, 'Business name is too short')
    .matches(COMPANY_REGEX, 'Business name contains invalid characters'),
  taxId: yup
    .string()
    .matches(TID_REGEX, 'Tax ID / EIN should be formatted XX-XXXXXXX')
    .required('Tax ID / EIN cannot be blank'),
  street: yup
    .string()
    .required('Street cannot be blank')
    .matches(STREET_REGEX, 'Street contains invalid characters'),
  street2: yup
    .string()
    .matches(STREET_REGEX, 'Street contains invalid characters'),
  city: yup
    .string()
    .matches(CITY_REGEX, 'City contains invalid characters')
    .required('City cannot be blank'),
  zipcode: yup
    .string()
    .required('ZIP code cannot be blank')
    .matches(ZIPCODE_REGEX, 'Invalid ZIP code format'),
  state: yup.string().required('State cannot be blank'),
  taxExempt: yup.bool().nullable().required('Select an option'),
  multipleLocations: yup.bool().nullable().required('Select an option'),
  complianceState: yup.string().when('multipleLocations', {
    is: false,
    then: yup.string().required('State is required'),
  }),
  complianceCity: yup.string().when(['multipleLocations', 'complianceState'], {
    is: (multipleLocations: boolean, complianceState: string) =>
      multipleLocations === false && statesWithCities.includes(complianceState),
    then: yup.string().required('Compliance city must be selected'),
  }),
  purpose: yup
    .string()
    .required('We need to confirm the purpose for background checks'),
});

export default Schema;
