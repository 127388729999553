import React, { useState, useCallback, useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { ADJUDICATION_VALUES } from '@dashboard-experience/utils';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';
import { useAdverseActionable, useGetReport } from 'api/reports';
import {
  CANDIDATE_REPORT_EVENT_NAMES,
  scrollToTop,
  useTrackEvent,
} from 'utils';
import Modal from '../../../adverse-action/initiate/flow';

const { PRE_ADVERSE_ACTION, POST_ADVERSE_ACTION } = ADJUDICATION_VALUES;

const PreAAButton = () => {
  const { report } = useGetReport();
  const trackEvent = useTrackEvent();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOpen = useCallback(() => {
    setOpen(true);
    trackEvent(
      CANDIDATE_REPORT_EVENT_NAMES.CUSTOMER_SELECTED_CANDIDATE_ACTION,
      { Action: 'Pre Adverse Action' },
    );
    // TODO: remove once customer is standalone app
    scrollToTop();
  }, [setOpen]);

  // TODO: Follow up on error handling
  const { adverseActionable, isLoading } = useAdverseActionable();
  const text = t(`${namespace}:report.actions.pre_adverse.action`);

  const hideButton =
    report?.adjudication === PRE_ADVERSE_ACTION ||
    report?.adjudication === POST_ADVERSE_ACTION ||
    !adverseActionable?.enabled ||
    report.archived;

  useEffect(() => {
    if (hideButton) {
      setOpen(false);
    }
  }, [hideButton]);

  if (isLoading)
    return (
      <M.LoadingInline style={{ marginRight: '1rem', marginLeft: '1rem' }} />
    );
  if (hideButton) return null;

  return (
    <>
      <M.Button
        data-testid='pre-adverse-action-button'
        kind='secondary-destructive'
        onClick={handleOpen}
      >
        {text}
      </M.Button>
      {open && <Modal onClose={handleClose} open={open} />}
    </>
  );
};

export default PreAAButton;
