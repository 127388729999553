import React, { useCallback, useEffect, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { findEnvVar } from '@dashboard-experience/utils';
import { CandidatePostalAddress } from 'types';
import styled from 'styled-components';

const AddressAutocompleteWrapper = styled.div.attrs({
  className: 'create-account-address-autocomplete-wrapper',
})`
  .cds--form-requirement {
    color: ${colors.uiOrange600} !important;
  }

  .cds--list-box__invalid-icon--warning {
    fill: ${colors.uiOrange600} !important;
  }

  .cds--list-box__invalid-icon--warning path[fill] {
    fill: ${colors.white} !important;
  }
`;

type Props = {
  disableFields: boolean;
  setFieldValue: (field: string, value: any) => void;
  showError: (field: string) => boolean;
  errorMessage: (field: string) => string | null;
};

const AddressAutocomplete = ({
  disableFields,
  setFieldValue,
  showError,
  errorMessage,
}: Props) => {
  const [selected, setSelected] = useState(null);
  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: `${findEnvVar('GOOGLE_PLACES_API_KEY')}&loading=async`,
    });

  const error =
    showError('street') ||
    showError('street2') ||
    showError('city') ||
    showError('state') ||
    showError('zipcode');

  const handleAddress = useCallback(
    address => {
      setFieldValue('street', address.street || '');
      setFieldValue('street2', address.street2 || '');
      setFieldValue('city', address.city || '');
      setFieldValue('state', address.state || '');
      setFieldValue('zipcode', address.zipcode || '');
    },
    [setFieldValue],
  );

  const parseAddress = useCallback(
    addressComponents => {
      const newAddress: CandidatePostalAddress = {};

      addressComponents.forEach((component: any) => {
        if (component.types.includes('street_number')) {
          newAddress.street = newAddress.street
            ? component.long_name.concat(` ${newAddress.street}`)
            : component.long_name;
        }
        if (component.types.includes('route')) {
          newAddress.street = newAddress.street
            ? newAddress.street.concat(` ${component.short_name}`)
            : component.short_name;
        }
        if (component.types.includes('subpremise')) {
          newAddress.street2 = component.short_name;
        }
        if (component.types.includes('locality')) {
          newAddress.city = component.long_name;
        }
        if (component.types.includes('administrative_area_level_1')) {
          newAddress.state = component.short_name;
        }
        if (component.types.includes('postal_code')) {
          newAddress.zipcode = component.long_name;
        }
      });
      handleAddress(newAddress);
    },
    [handleAddress],
  );

  useEffect(() => {
    if (selected) {
      placesService?.getDetails(
        {
          // @ts-ignore
          placeId: selected.place_id,
        },
        placeDetails => {
          if (placeDetails) parseAddress(placeDetails.address_components);
        },
      );
    }
  }, [parseAddress, placesService, selected]);

  const getPredictions = useCallback(
    (text: string) => {
      getPlacePredictions({
        input: text,
        locationBias: 'IP_BIAS',
        region: 'us',
        componentRestrictions: { country: 'us' },
      });
    },
    [getPlacePredictions],
  );

  const displayPrediction = useCallback(
    (prediction: google.maps.places.AutocompletePrediction) => {
      return prediction ? prediction.description : '';
    },
    [],
  );

  const handleSelect = useCallback(
    ({ selectedItem }) => {
      if (!selectedItem) {
        setFieldValue('street', '');
        setFieldValue('street2', '');
        setFieldValue('city', '');
        setFieldValue('state', '');
        setFieldValue('zipcode', '');
      }
      setSelected(selectedItem);
    },
    [setFieldValue],
  );

  return (
    <AddressAutocompleteWrapper>
      <M.ComboBox
        disabled={disableFields}
        id='address-select'
        onChange={handleSelect}
        onInputChange={getPredictions}
        items={placePredictions}
        itemToString={displayPrediction}
        initialSelectedItem={selected}
        invalid={!!error}
      />
    </AddressAutocompleteWrapper>
  );
};

export default AddressAutocomplete;
