import { Screening } from 'types';

export const basicPackage = [
  {
    type: 'ssn_trace',
  },
  {
    type: 'sex_offender_search',
  },
  {
    type: 'global_watchlist_search',
  },
  {
    type: 'national_criminal_search',
    subtype: 'standard',
  },
] as Screening[];

export const essentialPackage = [
  ...basicPackage,
  {
    type: 'county_criminal_search',
    subtype: '7years',
    requires_education_history: false,
    requires_employment_history: false,
  },
] as Screening[];

export const professionalPackage = [
  ...essentialPackage,
  {
    type: 'education_verification',
    requires_education_history: false,
    international_upgrade: false,
  },
  {
    type: 'employment_verification',
    lookback_years: 7,
    requires_full_lookback: false,
    maximum_allowed_employers: 1,
    requires_employment_history: false,
    international_upgrade: false,
    salary: false,
    manager_contact: true,
    contract_type: true,
    date_matching: 6,
    name: 'EMPLOYERS_LAST_7_YEARS',
  },
] as Screening[];

export const BASIC_SCREENINGS: string[] = [
  'SSN trace',
  'Sex offender registry search',
  'Global watchlist search',
  'National criminal search',
];

export const ESSENTIAL_SCREENINGS: string[] = [
  ...BASIC_SCREENINGS,
  'Country criminal search (7-year)',
];

export const PROFESSIONAL_SCREENINGS: string[] = [
  ...ESSENTIAL_SCREENINGS,
  'Education verification',
  'Employment verification',
];

export const INTERNATIONAL_BASIC_SCREENINGS: string[] = [
  'International criminal (or adverse media)',
  'Global watchlist search',
];

export const INTERNATIONAL_PROFESSIONAL_SCREENINGS: string[] = [
  ...INTERNATIONAL_BASIC_SCREENINGS,
  'Education verification',
  'Employmnet verification',
];

export const INTERNATIONAL_PROFESSIONAL_PLUS_SCREENINGS: string[] = [
  ...INTERNATIONAL_PROFESSIONAL_SCREENINGS,
  'Identity document verification',
];

export const ADD_ONS_PRICES = {
  motor_vehicle_report: 950,
  education_verification: 1250,
  employment_verification: 3500,
  federal_criminal_search: 1000,
};
