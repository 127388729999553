/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/prefer-default-export */
import React, { useMemo } from 'react';
import * as IconSet from './icons';

const getIconFromName = (tabName: string) => {
  switch (tabName) {
    case 'dashboard':
      return IconSet.Home;
    case 'candidates':
      return IconSet.AddressBook;
    case 'continuous_services':
      return IconSet.Continuous;
    case 'adverse_actions':
      return IconSet.AdverseActions;
    case 'assess':
      return IconSet.Assess;
    case 'trust':
      return IconSet.ExternalLink;
    case 'fair_chance':
      return IconSet.FairChance;
    case 'packages':
      return IconSet.Packages;
    case 'i9':
      return IconSet.Shield;
    case 'pay':
      return IconSet.Bank;
    case 'help_center':
      return IconSet.Help;
    case 'account':
      return IconSet.Globe;
    case 'order_background_check':
      return IconSet.OrderBGC;
    case 'analytics':
      return IconSet.Analytics;
    case 'logs':
      return IconSet.Code;
    case 'screening':
      return IconSet.Settings;
    case 'billing':
      return IconSet.Receipt;
    case 'collapse':
      return IconSet.Collapse;
    case 'expand':
      return IconSet.Expand;
    case 'mail':
      return IconSet.Mail;
    case 'case_management':
      return IconSet.Tasks;
    case 'random_testing_program':
      return IconSet.RandomTesting;
    default:
      return IconSet.Help;
  }
};

type Props = {
  tabName: string;
};

export const NavIcon: React.FC<Props> = ({ tabName }) => {
  const Icon = useMemo(() => {
    return getIconFromName(tabName);
  }, [tabName]);

  return <Icon />;
};
