import React, { FC } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import PageHeaderSingle from '../PageHeaderSingle';

const PageDesc = styled.div`
  display: block;
  width: 100%;
  margin-top: 10px;
  font-size: 0.9rem !important;
`;

const headerNode = (
  <PageDesc>
    Order tests if your employee was in an accident or you suspect they are
    using drugs or alcohol.
  </PageDesc>
);

const PARSHeader: FC = () => {
  return (
    <M.Grid>
      <M.GridRow>
        <M.GridCol>
          <PageHeaderSingle
            dataTestId='pars-title'
            className='header-wrap-secondary'
            title={{
              label: 'Accident & suspicion testing',
            }}
            headerNode={headerNode}
          />
        </M.GridCol>
      </M.GridRow>
    </M.Grid>
  );
};

export default PARSHeader;
