import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import ManualOrderModal from 'components/Packages/OrderBackgroundCheck/GetStarted/ManualOrderModal';
import { ManualOrdersReview } from 'components/Packages/OrderBackgroundCheck/Context';
import { useRequestManualOrders } from 'api/accounts';
import { GetStartedT, GetStartedE } from '../..';
import InfoSourceRadioMyself from './InfoSourceRadioMyself';
import { namespace } from '../../locales';
import { ChoiceCardWrap } from '../GetStartedStep.styles';
import { ChoiceCardGroup } from './InfoSourceRadios.styles';

type InfoSourceRadiosProps = {
  selectedInfoSource?: GetStartedT.InfoSource;
  isInternational: boolean;
  setSelectedInfoSource: Dispatch<
    SetStateAction<GetStartedT.InfoSource | undefined>
  >;
  manual_orders_review: GetStartedE.ManualOrdersReview;
  accountId: string;
};

const InfoSourceRadios = ({
  selectedInfoSource,
  isInternational,
  setSelectedInfoSource,
  manual_orders_review,
  accountId,
}: InfoSourceRadiosProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation('better_order_experience');
  const basePath = `${namespace}:candidateDetails.infoSourceRadios`;
  const showManualOrderText =
    manual_orders_review === GetStartedE.ManualOrdersReview.APPROVED &&
    selectedInfoSource === 'MYSELF';
  const { call: requestManualOrdersCall } = useRequestManualOrders();

  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleManualOrderSubmit = useCallback(() => {
    requestManualOrdersCall({ accountId });
  }, [accountId, requestManualOrdersCall]);

  const handleRadioClick = useCallback(
    e => {
      setSelectedInfoSource(e.target.value);
    },
    [setSelectedInfoSource],
  );

  return (
    <>
      <ChoiceCardGroup>
        <ChoiceCardWrap>
          <M.ChoiceCard
            key='candidate'
            data-testid='info-source-radio-group-candidate'
            title={t(`${basePath}.candidate.title`)}
            description={t(`${basePath}.candidate.description`)}
            value='CANDIDATE'
            name='CANDIDATE'
            onClick={handleRadioClick}
            checked={selectedInfoSource === 'CANDIDATE'}
          />
        </ChoiceCardWrap>

        <InfoSourceRadioMyself
          isInternational={isInternational}
          manual_orders_review={manual_orders_review}
          handleModalOpen={handleModalOpen}
          onClick={handleRadioClick}
          checked={selectedInfoSource === 'MYSELF'}
        />
      </ChoiceCardGroup>
      {showManualOrderText && (
        <p>
          {t(`${basePath}.myself.subtext`)}{' '}
          <M.Link href='#' onClick={handleModalOpen}>
            {t(`${basePath}.myself.subtextLink`)}
          </M.Link>
        </p>
      )}
      <ManualOrderModal
        show={modalOpen}
        handleClose={handleModalClose}
        handleSubmit={handleManualOrderSubmit}
        manualOrdersReviewStatus={manual_orders_review as ManualOrdersReview}
      />
    </>
  );
};

export default InfoSourceRadios;
