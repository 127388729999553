import React from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { ReportStatuses } from '@dashboard-experience/utils';
import { Report } from 'types';
import { useReportInvitationStatusDecision } from '../utils';
import ReportsInvitationsDropdown from './reports-invitations-dropdown';
import ReportInvitationInformationExpanded from './report-invitation-information-expanded';
import ReportInvitationDateStatus from './report-invitation-date-status';

type Props = {
  isScrolled: boolean;
  reportsLoading: boolean;
  selectedReport?: Report;
  reports: Report[];
};

const FlexContainer = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 12px;
  }

  span .report-label {
    color: rgba(26, 32, 38, 0.78);
  }
`;

const StyledDivider = styled.div`
  height: 24px;
  width: 1px;
  background-color: ${colors.uiGrey200};
`;

const ReportInvitationInformation: React.FC<Props> = ({
  isScrolled,
  reportsLoading,
  selectedReport,
  reports,
}) => {
  // @ts-ignore TODO: Remove comment once the Report type is consumed from Utils
  const status = useReportInvitationStatusDecision(selectedReport);
  const showDropdown = reports.length > 1 && status !== ReportStatuses.EXPIRED;

  return (
    <FlexContainer data-testid='candidate-header-report-information'>
      {isScrolled ? (
        <M.Icon icon='ChevronRight' size='20' style={{ opacity: '0.5' }} />
      ) : (
        <>
          {reportsLoading ? (
            <M.LoadingInline />
          ) : (
            <ReportInvitationInformationExpanded report={selectedReport} />
          )}
          {showDropdown && <StyledDivider />}
        </>
      )}

      {selectedReport &&
        (showDropdown ? (
          <ReportsInvitationsDropdown
            isScrolled={isScrolled}
            reportsLoading={reportsLoading}
            selectedReport={selectedReport}
            reports={reports}
          />
        ) : (
          <>
            {isScrolled && (
              <ReportInvitationDateStatus report={selectedReport} />
            )}
          </>
        ))}
    </FlexContainer>
  );
};

export default ReportInvitationInformation;
