import { M } from '@dashboard-experience/mastodon';
import { useGetReportExceptions } from 'api/reports';
import { useReport } from 'containers/Report';
import { CANDIDATE_REDESIGN } from 'Flags';
import { useCandidateRededesignPhaseFour } from 'modules/candidate/utilities';
import { BetaState, useBetaState } from 'providers/Beta';
import { useCandidateExceptions } from 'providers/Candidate';
import React from 'react';
import ResolvableExceptionsBanners from './resolvable-exceptions-banners';

const ExceptionBanners = () => {
  const betaState = useBetaState(CANDIDATE_REDESIGN);
  const candidateRedesignPhaseFourEnabled = useCandidateRededesignPhaseFour();
  const report = useReport();
  const { exceptions = [] } = useGetReportExceptions(report?.id);
  const candidateExceptions = useCandidateExceptions() || [];

  // None of these banners should be shown to people without the redesign flags
  if (betaState === BetaState.DISABLED || !candidateRedesignPhaseFourEnabled) {
    return null;
  }

  const unresolvedExceptions = [...exceptions, ...candidateExceptions].filter(
    exception => !exception.resolved_at,
  );

  if (!unresolvedExceptions.length) {
    return null;
  }

  return (
    <M.Grid>
      <M.GridRow>
        <ResolvableExceptionsBanners
          unresolvedExceptions={unresolvedExceptions}
        />
        {/* Add banners for unresolvable exceptions and canceled screenings here */}
      </M.GridRow>
    </M.Grid>
  );
};

export default ExceptionBanners;
