import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_BULK_INVITATIONS } from 'Constants';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import {
  emptyInvite,
  validateEmail,
  validateFirstName,
  validatePhoneNumber,
} from 'components/BetterOrderExperience/utils';
import type { GetStartedT } from 'components/BetterOrderExperience';
import { namespace } from '../../../locales';
import InviteEntryRow from './InviteEntryRow';

const FormContainer = styled.div`
  border-radius: 4px;
  border: 1px solid ${colors.uiGrey200};
  padding: 16px;
`;

type ManualInviteEntriesType = {
  enteredInvites: GetStartedT.Invite[];
  setEnteredInvites: Dispatch<SetStateAction<GetStartedT.Invite[]>>;
};

const ManualInviteEntries = ({
  enteredInvites,
  setEnteredInvites,
}: ManualInviteEntriesType) => {
  const { t } = useTranslation();
  const basePath = `${namespace}:candidateContactInfo.manual.inviteEntry`;
  const invites = enteredInvites.length > 0 ? enteredInvites : [emptyInvite()];

  const handleAddInvite = useCallback(() => {
    setEnteredInvites([...invites, emptyInvite()]);
  }, [setEnteredInvites, invites]);

  const handleRemoveInvite = useCallback(
    inviteId => {
      const filteredInvites = invites.filter(invite => invite.id !== inviteId);
      setEnteredInvites(filteredInvites);
    },
    [setEnteredInvites, invites],
  );

  const handleEmailChange = useCallback(
    (e, inviteId) => {
      const { name, value } = e.target;
      const updatedInvites = invites.map(invite =>
        inviteId === invite.id
          ? {
              ...invite,
              [name]: {
                value,
                isValid: validateEmail(value),
              },
            }
          : invite,
      );
      setEnteredInvites(updatedInvites);
    },
    [setEnteredInvites, invites],
  );

  const handleNameChange = useCallback(
    (e, inviteId) => {
      const { name, value } = e.target;
      const updatedInvites = invites.map(invite =>
        inviteId === invite.id
          ? {
              ...invite,
              [name]: {
                value,
                isValid: validateFirstName(value, true),
              },
            }
          : invite,
      );
      setEnteredInvites(updatedInvites);
    },
    [setEnteredInvites, invites],
  );

  const handlePhoneChange = useCallback(
    (e, inviteId) => {
      const { name, value } = e.target;
      const updatedInvites = invites.map(invite =>
        inviteId === invite.id
          ? {
              ...invite,
              [name]: {
                value,
                isValid: validatePhoneNumber(value, true),
              },
            }
          : invite,
      );
      setEnteredInvites(updatedInvites);
    },
    [setEnteredInvites, invites],
  );

  return (
    <>
      <p className='p4'>
        {t(`${basePath}.enterManuallyOrPaste`, {
          maxNumber: MAX_BULK_INVITATIONS,
        })}
      </p>
      <FormContainer>
        {invites.map((invite, i) => (
          <>
            <InviteEntryRow
              index={i}
              key={`invite-entry-${invite.id}`}
              invites={invites}
              handleRemoveInvite={handleRemoveInvite}
              handleEmailChange={handleEmailChange}
              handleNameChange={handleNameChange}
              handlePhoneChange={handlePhoneChange}
            />
            {i < invites.length - 1 && <M.Divider />}
          </>
        ))}
        <M.Button
          kind='tertiary'
          data-testid='add-another-candidate-button'
          onClick={handleAddInvite}
        >
          <M.Icon icon='Add' /> {t(`${basePath}.addAnother`)}
        </M.Button>
      </FormContainer>
    </>
  );
};

export default ManualInviteEntries;
