import React, { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  CANDIDATE_REPORT_EVENT_NAMES,
  scrollToTop,
  useTrackEvent,
} from 'utils';
import { namespace } from 'modules/adjudication/locales';
import { useTranslation } from 'react-i18next';
import { useCancelAdverseAction } from 'modules/adjudication/api';
import { useDisplayCancelAdverseAction } from 'modules/adjudication/utilities';
import { ConfirmDangerModal } from 'components';
import H3 from '../../../common/h3';

const useCancelAction = () => {
  const [openModal, setOpenModal] = useState(false);
  const trackEvent = useTrackEvent();
  const { call } = useCancelAdverseAction();
  const displayCancelAdverseAction = useDisplayCancelAdverseAction();
  const container = (document.getElementById('mastodon') ||
    document.body) as Element;

  const { t } = useTranslation();
  const header = t(`${namespace}:report.actions.cancel.modal.header`);
  const warn = <H3>{t(`${namespace}:report.actions.cancel.modal.msg`)}</H3>;
  const confirm = t(`${namespace}:report.actions.cancel.modal.confirm`);
  const title = t(`${namespace}:report.actions.cancel.action`);
  const subtitle = t(`${namespace}:report.actions.cancel.description`);

  const action = useCallback(() => {
    setOpenModal(true);
    trackEvent(
      CANDIDATE_REPORT_EVENT_NAMES.CUSTOMER_SELECTED_CANDIDATE_ACTION,
      { Action: 'Cancel Adverse Action' },
    );
    scrollToTop();
  }, [trackEvent]);

  const hideConfirmModal = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const handleConfirm = useCallback(() => {
    call();
    hideConfirmModal();
  }, [call, hideConfirmModal]);

  const modal = openModal
    ? createPortal(
        // @ts-ignore
        <ConfirmDangerModal
          className='actions-modal'
          header={header}
          callback={handleConfirm}
          confirmBtnName={confirm}
          open={openModal}
          hideModal={hideConfirmModal}
          message={warn}
        />,
        container,
      )
    : null;

  return displayCancelAdverseAction
    ? {
        title,
        subtitle: <div dangerouslySetInnerHTML={{ __html: subtitle }} />,
        action,
        modal,
      }
    : null;
};

export default useCancelAction;
