import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import pick from 'lodash/pick';
import styled from 'styled-components';
import { fetchAiExplanation } from 'api/aiExplainr';
import { useTrackEvent, AI_CHARGE_EXPLAINER_FEEDBACK } from 'utils/analytics';
import { useQuery } from 'react-query';
import { M, colors, fonts } from '@dashboard-experience/mastodon';
import FeedbackButtons from './FeedbackButtons';

type Charge = {
  id: string;
  charge: string;
  charge_type: string;
  sentence: string;
  disposition: string;
  arrest_date: string;
  disposition_date: string;
  category: string;
  charge_category: string;
  charge_subcategory: string;
  charge_subsubcategory: string;
  county: string;
  state: string;
};

const amplitudeFields = [
  'charge',
  'charge_type',
  'record_type',
  'category',
  'charge_category',
  'charge_subcategory',
  'charge_subsubcategory',
  'state',
  'county',
];

function mapToChargeObject(input: any, state: string, county: string): Charge {
  return {
    ...input,
    state,
    county,
  };
}

const StyledLoadingTitle = styled.h3`
  color: ${colors.uiGrey900};
  font-family: ${fonts.nationalRegular};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const ChargeExplainer: React.FC<{
  charge: any;
  state: string;
  county: string;
}> = ({ charge, state, county }) => {
  const chargeObject = mapToChargeObject(charge, state, county);
  const recordType = charge.assessment?.record_type || 'criminal_charge';
  const trackEvent = useTrackEvent();

  useEffect(() => {
    const chargeData = pick(charge, amplitudeFields);
    trackEvent(
      AI_CHARGE_EXPLAINER_FEEDBACK.AI_CHARGE_EXPLAINER__MAIN_BUTTON_CLICKED,
      {
        Charge: chargeData,
      },
    );
  }, [charge, trackEvent]);

  const { data, error, isLoading } = useQuery(
    ['ai-charge-explainer', recordType, charge.id],
    async () => {
      const explanation = await fetchAiExplanation(recordType, chargeObject);
      return explanation;
    },
    {
      enabled: !!chargeObject,
      staleTime: 1000 * 60 * 5,
    },
  );

  if (isLoading) {
    return (
      <>
        <StyledLoadingTitle>{charge.charge}</StyledLoadingTitle>
        <M.LoadingInline description='Defining this charge...' />
      </>
    );
  }

  if (error) {
    return (
      <ReactMarkdown>
        Sorry, our AI is not working right now, please try again later
      </ReactMarkdown>
    );
  }

  if (data) {
    return (
      <>
        <ReactMarkdown>{data.content}</ReactMarkdown>
        <FeedbackButtons
          charge={charge}
          explanation_id={data.explanation_id}
          record_type={recordType}
        />
      </>
    );
  }

  return null;
};

export default ChargeExplainer;
