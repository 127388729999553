import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTrackEvent, AI_CHARGE_EXPLAINER_FEEDBACK } from 'utils/analytics';

type FeedbackModalProps = {
  open: boolean;
  hideModal: () => void;
  submitFeedback: () => void;
  explanation_id: string;
  selectedFeedback: string | null;
  setSelectedFeedback: React.Dispatch<React.SetStateAction<string | null>>;
};

const items = [
  'This is not relevant or isn’t what I expected',
  'This is inaccurate or misleading',
  'This is offensive or harmful',
  'Something else',
];

const FeedbackModal: React.FC<FeedbackModalProps> = ({
  open,
  hideModal,
  explanation_id,
  selectedFeedback,
  setSelectedFeedback,
  submitFeedback,
}) => {
  const trackEvent = useTrackEvent();

  const updateFeedback = useCallback(
    e => {
      const selectedItem = e.target?.value;
      setSelectedFeedback(selectedItem);
    },
    [setSelectedFeedback],
  );

  const handleSelectAndSubmitFeedback = useCallback(() => {
    if (selectedFeedback !== null) {
      trackEvent(
        AI_CHARGE_EXPLAINER_FEEDBACK.AI_CHARGE_EXPLAINER_FEEDBACK_REASON_SELECTED,
        {
          Feedback: selectedFeedback,
          explanation_id,
        },
      );
      submitFeedback();
    }
  }, [selectedFeedback, explanation_id, trackEvent, submitFeedback]);

  return open ? (
    <M.ComposedModal onClose={hideModal} open={open} style={{ zIndex: 99999 }}>
      <M.ModalHeader closeModal={hideModal}>
        <h2>Help us improve Charge Intelligence</h2>
      </M.ModalHeader>
      <M.ModalBody>
        <p style={{ paddingBottom: '20px' }}>
          Was there a problem with this charge definition? Let us know what
          happened.
        </p>

        <M.Select
          id='feedback-select'
          placeholder='I want to share...'
          onChange={updateFeedback}
          labelText='Select Feedback'
        >
          {items.map(item => (
            <M.Select.Item key={item} value={item} text={item} />
          ))}
        </M.Select>
      </M.ModalBody>
      <M.ModalFooter>
        <M.BinaryButtons
          btnLeft={{ name: 'Cancel', onClick: hideModal }}
          btnRight={{
            name: 'Submit Feedback',
            disabled: selectedFeedback === null,
            onClick: handleSelectAndSubmitFeedback,
          }}
        />
      </M.ModalFooter>
    </M.ComposedModal>
  ) : null;
};

export default FeedbackModal;
