import { Package } from '../../../types';

export type AliasTagProps = {
  basePackage: Package;
};

export enum AliasesEnabledType {
  ON = 'on',
  OFF = 'off',
}
