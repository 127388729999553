import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/candidate/locales';
import { SectionLabel } from '../styles';

const RightPanel = () => {
  const { t } = useTranslation();
  const label = t(`${namespace}:report.settings.menu.right-panel.label`);

  return (
    <>
      <SectionLabel>{label}</SectionLabel>
      <M.ActionMenuItem title='Test item' />
    </>
  );
};

export default RightPanel;
