import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { Package, ScreeningSetting } from 'types';
import { useUser } from 'context/CurrentUser';
import { usePackages } from 'api/packages';
import PackageCard from './PackageCard';
import ManualTestInstructions from './ManualTestInstructions';

const InstructionTitle = styled.p`
  color: ${colors.brandNavy4};
  font-size: 1rem !important;
  font-weight: bold;
  line-height: 1 !important;
  align-self: center;
  margin-top: 6px !important;
`;

const Tooltip = styled(M.TooltipDefinition)`
  align-items: center;
  margin-left: 0.7rem;
  text-align: center;
`;

const InformationIcon = styled(M.Icon)`
  fill: ${colors.uiNavy600} !important;
  vertical-align: text-bottom;
`;

const FlexContainerWithBorder = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  width: 100%;
  max-width: none;
  margin: 1em;
  width: 100%;
`;
const EmptyStateContainer = styled.div`
  background-color: ${colors.uiNavy50};
  padding: 1.5rem;
  width: 100%;
  background-color: ${colors.uiNavy50} !important;
  border: 1px solid ${colors.borderPrimaryLight};
  border-radius: 0.5rem;

  p {
    font-size: 0.9rem !important;
    line-height: 24px !important;
    margin: 0 !important;
  }

  .spaced-paragraph {
    margin-top: 1rem !important;
  }

  b {
    display: block;
    margin-bottom: 1rem;
    font-size: 1rem !important;
    font-weight: bold;
    line-height: 1 !important;
  }
`;

const FlexGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
`;

const NavyText = styled.p`
  color: ${colors.uiNavy600} !important;
  cursor: pointer;
  margin-top: 1rem;
  text-align: left;
  font-size: 0.9rem !important;

  &:hover {
    text-decoration: underline;
  }
`;

const PARSBody: FC = () => {
  const currentUser = useUser();

  const { data: packages, isLoading: packagesLoading } = usePackages(
    currentUser?.account.id,
    new URLSearchParams({}),
  );

  const [isModalOpen, setModalOpen] = useState(false);

  const handleModalToggle = useCallback(() => {
    setModalOpen(prev => !prev);
  }, []);

  const contact_checkr_link = (
    <a
      href='https://help.checkr.com/hc/en-us/requests/new'
      target='_blank'
      rel='noreferrer'
    >
      contact Checkr
    </a>
  );

  const parsPackages: Package[] = packages?.filter((pkg: Package) => {
    const hasEnabledExams = pkg.enabled_exams !== null;
    const hasValidScreeningSettings =
      pkg.screening_settings &&
      pkg.screening_settings.some(
        (setting: ScreeningSetting) =>
          (setting.value === 'POST_ACCIDENT' ||
            setting.value === 'REASONABLE_SUSPICION') &&
          setting.screening_type === 'drug_screening',
      );
    return hasEnabledExams && hasValidScreeningSettings;
  });

  return (
    <M.Grid>
      {packagesLoading && (
        <M.GridRow>
          <M.GridCol>
            <M.LoadingInline description='Loading packages...' />
          </M.GridCol>
        </M.GridRow>
      )}

      {!packagesLoading && (
        <>
          {(!parsPackages || parsPackages?.length === 0) && (
            <FlexContainerWithBorder>
              <EmptyStateContainer data-testid='no-pars-packages-instuctions'>
                <p>
                  <b>Getting started</b>
                </p>
                <p>
                  1. To create post-accident and reasonable suspicion tests,{' '}
                  {contact_checkr_link}.
                </p>
                <p>
                  2. Distribute instructions and links to supervisors who will
                  be your reporters per your testing protocol.
                </p>
                <p className='spaced-paragraph'>
                  You can include instructions in company manuals, wiki pages,
                  training documents, or even printed as a QR code in your
                  facility.
                </p>
              </EmptyStateContainer>
            </FlexContainerWithBorder>
          )}

          {parsPackages && parsPackages.length !== 0 && (
            <>
              <M.GridRow>
                <M.GridCol>
                  <InstructionTitle>
                    Use these links to start the testing process
                    <Tooltip
                      align='top-left'
                      highlighted={false}
                      definition={
                        <>
                          To create, edit, or remove tests,{' '}
                          {contact_checkr_link}.
                        </>
                      }
                    >
                      <InformationIcon icon='InformationFilled' size='20px' />
                    </Tooltip>
                  </InstructionTitle>
                </M.GridCol>
              </M.GridRow>

              <FlexContainerWithBorder>
                <FlexGrid>
                  {parsPackages.map(pkg => (
                    <PackageCard key={pkg.id} pkg={pkg} />
                  ))}
                </FlexGrid>
              </FlexContainerWithBorder>
            </>
          )}

          <M.GridRow>
            <M.GridCol>
              <NavyText onClick={handleModalToggle}>
                Schedule a test by phone instead.
              </NavyText>
            </M.GridCol>
          </M.GridRow>
          <ManualTestInstructions
            isOpen={isModalOpen}
            handleModalToggle={handleModalToggle}
          />
        </>
      )}
    </M.Grid>
  );
};

export default PARSBody;
