import React, { useCallback, useState } from 'react';
import {
  CANDIDATE_REPORT_EVENT_NAMES,
  scrollToTop,
  useTrackEvent,
} from 'utils';
import { createPortal } from 'react-dom';
import { namespace } from 'modules/adjudication/locales';
import { useTranslation } from 'react-i18next';
import { Report } from 'types';
import {
  useResumeAdverseAction,
  useAdverseActions,
} from 'modules/adjudication/api';
import { accountHasPermission } from '@dashboard-experience/utils';
import { useGetReport } from 'api/reports';

import Modal from '../../../adverse-action/resume/modal';

const useResumeAction = () => {
  const trackEvent = useTrackEvent();
  const { t } = useTranslation();
  const { call } = useResumeAdverseAction();
  const [modalOpen, setOpenModal] = useState(false);

  const { report } = useGetReport() as unknown as Report;
  const canPause = accountHasPermission(report, 'adverse_action_pause');
  const { adverseAction } = useAdverseActions(report?.id);
  const hasPauseData = adverseAction?.pause_data;

  const displayResumeAdverseAction = canPause && hasPauseData;
  const title = t(`${namespace}:report.actions.resume.action`);
  const subtitle = t(`${namespace}:report.actions.resume.description`);

  const container = (document.getElementById('mastodon') ||
    document.body) as Element;

  const action = useCallback(() => {
    setOpenModal(true);
    trackEvent(
      CANDIDATE_REPORT_EVENT_NAMES.CUSTOMER_SELECTED_CANDIDATE_ACTION,
      { Action: 'Resume Adverse Action' },
    );
    scrollToTop();
  }, [setOpenModal]);

  const hideModal = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const handleConfirm = useCallback(() => {
    call();
    hideModal();
  }, [call, hideModal]);

  const modal = modalOpen
    ? createPortal(
        <Modal
          open={modalOpen}
          hideModal={hideModal}
          callback={handleConfirm}
        />,
        container,
      )
    : null;

  return displayResumeAdverseAction
    ? {
        title,
        subtitle: <div dangerouslySetInnerHTML={{ __html: subtitle }} />,
        action,
        modal,
      }
    : null;
};

export default useResumeAction;
