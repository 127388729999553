import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';
import { SECONDARY_ACTIONS_EVENT_NAMES, useTrackEvent } from 'utils/analytics';
import AdjudicationSplitActionButton, {
  DropdownItem,
} from '../../common/split-action-button';

type Props = {
  dropdownItems: DropdownItem[];
  action: () => void;
};

const ResumeAdverseActionButton: React.FC<Props> = ({
  dropdownItems,
  action,
}) => {
  const { t } = useTranslation();
  const label = t(`${namespace}:report.actions.resume.action`);
  const trackEvent = useTrackEvent();

  const handleButtonAction = useCallback(() => {
    trackEvent(
      SECONDARY_ACTIONS_EVENT_NAMES.RESUME_ADVERSE_ACTION_MAIN_BUTTON_CLICKED,
      { Source: 'Main Button' },
    );

    action();
  }, [trackEvent, action]);

  return (
    <AdjudicationSplitActionButton
      buttonLabel={label}
      buttonAction={handleButtonAction}
      dropdownItems={dropdownItems}
      data-testid='resume-adverse-action-button'
    />
  );
};

export default ResumeAdverseActionButton;
