import { createContext, useContext } from 'react';
import type { ContextType } from './BetterOrderExperience.types';

type UpdateInput = Partial<ContextType>;

export type BetterOrderExperienceContextType = ContextType & {
  update: (values: UpdateInput) => void;
};

export const getInitialValues = () => {
  const storedValue = localStorage.getItem('betterOrderExperience');
  if (storedValue) {
    const storedObject = JSON.parse(storedValue);
    return storedObject;
  }
  return null;
};

const initialValues = getInitialValues();

export const INITIAL_VALUES = {
  infoSource: initialValues?.infoSource ?? 'CANDIDATE',
  geo: initialValues?.geo ?? {},
  location: initialValues?.location ?? {
    country: initialValues?.location?.country ?? undefined,
    state: initialValues?.location?.state ?? undefined,
    city: initialValues?.location?.city ?? undefined,
  },
  node: initialValues?.node ?? undefined,
  program: initialValues?.program ?? null,
  invites: initialValues?.invites ?? [],
  csv: initialValues?.csv ?? {},
  manualOrderType: initialValues?.manualOrderType ?? 'SINGLE',
  localeType: initialValues?.localeType ?? {},
  basePackage: initialValues?.basePackage ?? {},
  addedScreeningType: initialValues?.addedScreeningType ?? {},
  aliasAdded: initialValues?.aliasAdded ?? false,
  saveForNextTime: initialValues?.saveForNextTime ?? false,
  newPackageName: initialValues?.newPackageName ?? '',
  manualBulkUploadData: initialValues?.manualBulkUploadData ?? {},
  paymentProfile: initialValues?.paymentProfile ?? {},
};

const BetterOrderExperienceContext =
  createContext<BetterOrderExperienceContextType>({
    ...INITIAL_VALUES,
    update: () => {},
  });

export default BetterOrderExperienceContext;

export const useBetterOrderExperienceContext = () =>
  useContext(BetterOrderExperienceContext);
