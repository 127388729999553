import { M } from '@dashboard-experience/mastodon';
import { namespace } from 'modules/candidate/locales';
import moment from 'moment';
import { useCandidate } from 'providers/Candidate';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Exception } from 'types';
import { getExceptionDocumentTypes, getExpirationDaysMessage } from '../utils';

const StyledLink = styled(M.Link)`
  display: inline !important;
`;

const SingleExceptionBanner = ({ exceptions }: { exceptions: Exception[] }) => {
  const candidate = useCandidate();
  const { t } = useTranslation();

  if (exceptions.length !== 1) return null;
  const expirationDaysMessage = getExpirationDaysMessage(exceptions);
  const exception = exceptions[0];

  const exceptionDocumentTypes = getExceptionDocumentTypes(exception);

  if (!exceptionDocumentTypes) return null;

  const getBannerTitle = () => {
    const allDocumentTypes = exceptionDocumentTypes.split(', ');

    // When the exception type is `criminal_screening_requirement`, there can be multiple document
    // types that are parsed from the comment. This allows the title to be more readable
    // and we will still display all the document types in the bodyNode.
    if (allDocumentTypes.length > 1) {
      return t(`${namespace}:report.exceptions.banners.multipleTitle`);
    }
    return t(`${namespace}:report.exceptions.banners.singleTitle`, {
      exceptionDocumentTypes,
    });
  };

  return (
    <M.ExpandableNotification
      title={getBannerTitle()}
      secondaryTitle={moment(exception.created_at).format(
        'MMM D, YYYY h:mm A [PT]',
      )}
      bodyNode={
        <Trans
          t={t}
          i18nKey={`${namespace}:report.exceptions.banners.singleBody`}
          components={{
            1: <StyledLink href={exception.verification_uri} />,
            br: <br />,
          }}
          values={{
            firstName: candidate?.first_name || 'the candidate',
            exceptionDocumentTypes,
            expirationDaysMessage,
          }}
        />
      }
      kind='warning'
      initialExpanded
    />
  );
};

export default SingleExceptionBanner;
