import React, { useState, useMemo, useCallback, ReactNode } from 'react';
import {
  CurrentUser,
  useDebouncedCallback,
  parseNodeFilter,
} from '@dashboard-experience/utils';
import { LoadingScreen } from 'components';
import { useFetchNodes } from 'api/nodes';
import { useUser } from 'context/CurrentUser';
import { useGetData } from '../../components/Packages/hooks';
import AccountContext, { ContextType } from './AccountContext';

type Props = {
  children: ReactNode;
};

type ProviderValue = ContextType & {
  user: CurrentUser;
};

const Provider: React.FC<Props> = ({ children }) => {
  const [nodeSearchText, setNodeSearchText] = useState('');
  const { account, user } = useUser();

  const {
    geos = [],
    programs = [],
    payment_profiles,
    billing_entity_enabled,
    isLoading,
  } = useGetData({
    account,
  });

  const { data: { data: nodes = [] } = {}, isLoading: nodesIsLoading } =
    useFetchNodes({
      account,
      nodeName: parseNodeFilter(nodeSearchText)?.name,
    });

  const debouncedNodeSearch = useDebouncedCallback(
    searchText => {
      // Single Nodes are selected by default & the dropdown is disabled
      if (nodes.length > 1) {
        setNodeSearchText(searchText);
      }
    },
    350,
    [setNodeSearchText],
  );

  const handleNodeSearchTextChange = useCallback(
    searchText => {
      debouncedNodeSearch(searchText);
    },
    [debouncedNodeSearch],
  );

  const value = useMemo(
    () =>
      ({
        account,
        geos,
        nodes,
        nodesIsLoading,
        programs,
        payment_profiles,
        billing_entity_enabled,
        user,
        handleNodeSearchTextChange,
        nodeSearchText,
      } as ProviderValue),
    [
      account,
      geos,
      nodes,
      nodesIsLoading,
      programs,
      payment_profiles,
      billing_entity_enabled,
      user,
      handleNodeSearchTextChange,
      nodeSearchText,
    ],
  );

  if (isLoading) return <LoadingScreen />;

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
};

export default Provider;
