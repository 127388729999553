import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PackageCardProps } from 'components/Signup/types';
import { SIGNUP_EVENT_NAMES, useTrackEvent } from 'utils/analytics';
import PackageCard from '../../PackageCard';
import {
  ScreeningRow,
  ScreeningText,
  InformationIcon,
  Tooltip,
} from '../styles';
import IconCheckmark from '../IconCheckmark';

const EssentialPackageCard: React.FC<PackageCardProps> = ({
  selectedPackage,
  setSelectedPackage,
  showCompleteCrim,
}) => {
  const { t } = useTranslation('signup');
  const trackEvent = useTrackEvent();

  const handlePackageSelect = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_PACKAGE_CARD_CLICKED, {
      'Package Selected': 'Essential',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
    setSelectedPackage('checkrdirect_essential_criminal');
  }, [setSelectedPackage, trackEvent]);

  return (
    <PackageCard
      name='Essential'
      description={
        !showCompleteCrim
          ? 'In-depth criminal search, down to the county'
          : 'Enhanced criminal search for expanded results'
      }
      price={5499}
      packageType='checkrdirect_essential_criminal'
      mostPopular
      selected={selectedPackage === 'checkrdirect_essential_criminal'}
      onClick={handlePackageSelect}
    >
      <>
        <ScreeningRow className='screening-row'>
          <IconCheckmark />
          <ScreeningText fontWeight={700}>Everything in Basic+</ScreeningText>
        </ScreeningRow>
        <ScreeningRow className='screening-row'>
          <IconCheckmark />
          <ScreeningText className='screening-text'>
            County criminal search (7-year)
          </ScreeningText>
          <Tooltip
            align='right-top'
            label={t(`pages.packagesPage.tooltips.countyCriminal`)}
          >
            <InformationIcon icon='Information' size='20px' />
          </Tooltip>
        </ScreeningRow>
      </>
    </PackageCard>
  );
};

export default EssentialPackageCard;
