/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';

import {
  toastError,
  toastSuccess,
  updateAccountState,
  clearUserCache,
} from 'actions';

import { deleteAccount, updateAccount } from './actions';

export const useDeleteAccount = () => {
  const dispatch = useDispatch();
  const request = (accountId: string) => deleteAccount(accountId);

  const [deleteCall] = useMutation(request, {
    onError: (error: any) => {
      const errRes = error.response.data;
      let errMsg: string = '';
      if (errRes.error) {
        errMsg = errRes.error;
      } else {
        errMsg = errRes.errors.join(', ');
      }
      dispatch(toastError('Error deleting account', errMsg));
    },
  });

  return {
    deleteCall,
  };
};

export const useUpdateAccountSettings = () => {
  const dispatch = useDispatch();
  const request = ({ changedSettings, account, settings }: any) =>
    updateAccount(changedSettings, account, settings);

  const [call, result] = useMutation(request, {
    onError: (err: any) => {
      dispatch(toastError('Error updating account settings'));
    },
    onSuccess: (res: any, variables: any) => {
      const { changedSettings, isModal } = variables;
      dispatch(updateAccountState({ updatedAccount: res }));
      dispatch(clearUserCache());
      if (isModal) {
        changedSettings?.alias_auto_enable
          ? dispatch(
              toastSuccess(
                'Setting updated',
                'Future orders will search aliases, but you can customize each order.',
              ),
            )
          : dispatch(
              toastSuccess(
                'Setting updated',
                "Future orders won't search aliases, but you can customize each order.",
              ),
            );
      } else {
        dispatch(toastSuccess('Account settings updated'));
      }
    },
  });
  return {
    call,
    result,
  };
};

export const useUploadError = () => {
  const dispatch = useDispatch();
  const callUploadError = () => {
    dispatch(toastError('Failed to upload file'));
  };
  return {
    callUploadError,
  };
};
