import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { GetStartedT } from 'components/BetterOrderExperience';
import InfoMethodRadios from './InfoMethodRadios';
import { namespace } from '../../locales';
import { ManualInviteEntries } from './ManualInviteEntries';
import CandidateBulkUpload from './CandidateBulkUpload';

type CandidateContactInfoSectionProps = {
  enteredInvites: GetStartedT.Invite[];
  setEnteredInvites: Dispatch<SetStateAction<GetStartedT.Invite[]>>;
};

const CandidateContactInfoSection = ({
  enteredInvites,
  setEnteredInvites,
}: CandidateContactInfoSectionProps) => {
  const { t } = useTranslation(namespace);
  const manualBasePath = `${namespace}:candidateContactInfo.manual`;
  const [selectedInfoMethod, setSelectedInfoMethod] =
    useState<GetStartedT.InfoMethod>('MANUAL');

  return (
    <>
      <h4
        className='section-title'
        data-testid='candidate-contact-info-section-header'
      >
        {t('candidateContactInfo.header')}
      </h4>
      <InfoMethodRadios
        infoMethod={selectedInfoMethod}
        setSelectedInfoMethod={setSelectedInfoMethod}
      />
      {selectedInfoMethod === 'MANUAL' && (
        <>
          <h4 data-testid='candidate-contact-form-header'>
            {t(`${manualBasePath}.header`)}
          </h4>
          <p className='p2'>{t(`${manualBasePath}.descriptionOne`)}</p>
          <p className='p2'>{t(`${manualBasePath}.descriptionTwo`)}</p>
          <ManualInviteEntries
            enteredInvites={enteredInvites}
            setEnteredInvites={setEnteredInvites}
          />
        </>
      )}
      {selectedInfoMethod === 'UPLOAD' && <CandidateBulkUpload />}
    </>
  );
};

export default CandidateContactInfoSection;
