import { M } from '@dashboard-experience/mastodon';
import DeleteCandidateDataModal from 'components/Report/CandidateInformation/DeleteCandidateData';
import { useUser } from 'context/CurrentUser';
import { useCandidate } from 'providers/Candidate';
import React, { useCallback, useState } from 'react';
import { CANDIDATE_REPORT_EVENT_NAMES, useTrackEvent } from 'utils/analytics';

const DeleteCandidateData = () => {
  const candidate = useCandidate();
  const currentUser = useUser();
  const trackEvent = useTrackEvent();

  const [displayDeletionModal, setDisplayDeletionModal] =
    useState<boolean>(false);

  const showDeleteCandidateDataModal = useCallback(() => {
    if (currentUser) {
      trackEvent(CANDIDATE_REPORT_EVENT_NAMES.REPORT_DELETE_CANDIDATE_DATA);
    }

    setDisplayDeletionModal(true);
  }, [currentUser, trackEvent]);

  const closeDeletionModal = useCallback(
    completeType => {
      setDisplayDeletionModal(false);

      if (currentUser) {
        trackEvent(
          CANDIDATE_REPORT_EVENT_NAMES.REPORT_CANDIDATE_MODAL_COMPLETED,
          {
            'Complete Type': completeType,
          },
        );
      }
    },
    [currentUser, trackEvent],
  );

  return (
    <>
      <M.ActionMenuItem
        title='Delete candidate data'
        kind='node'
        actionNode={<M.Icon icon='TrashCan' size='16' />}
        onClick={showDeleteCandidateDataModal}
        // TODO: only add the divider when Download PDF is available
        // divider
      />
      {displayDeletionModal && (
        <DeleteCandidateDataModal
          showModal={displayDeletionModal}
          onClose={closeDeletionModal}
          candidate={candidate}
          data-testid='delete-candidate-data-modal'
        />
      )}
    </>
  );
};

export default DeleteCandidateData;
