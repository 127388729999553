import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { ChoiceCardWrap } from '../GetStartedStep.styles';

export const Header = styled.p`
  color: ${colors.coolBlack100};
  margin: 2.5rem 0 1rem !important;
`;

export const RadioWrap = styled(ChoiceCardWrap)`
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;

export const ChoiceCardGroup = styled.div`
  display: flex;
  margin: 1rem; 0;
  
  @media all and (max-width: 480px) {
    flex-flow: column;
    gap: 1rem;
  }
`;
