import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PackageCardProps } from 'components/Signup/types';
import { SIGNUP_EVENT_NAMES, useTrackEvent } from 'utils/analytics';
import PackageCard from '../../PackageCard';
import {
  ScreeningRow,
  ScreeningText,
  InformationIcon,
  Tooltip,
} from '../styles';
import IconCheckmark from '../IconCheckmark';

const CompletePackageCard: React.FC<PackageCardProps> = ({
  selectedPackage,
  setSelectedPackage,
}) => {
  const { t } = useTranslation('signup');
  const trackEvent = useTrackEvent();

  const handlePackageSelect = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_PACKAGE_CARD_CLICKED, {
      'Package Selected': 'Complete',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
    setSelectedPackage('checkrdirect_complete_criminal');
  }, [setSelectedPackage, trackEvent]);

  return (
    <PackageCard
      name='Complete'
      description='Maximum criminal search across our most comprehensive databases'
      price={8999}
      packageType='checkrdirect_complete_criminal'
      selected={selectedPackage === 'checkrdirect_complete_criminal'}
      onClick={handlePackageSelect}
    >
      <>
        <ScreeningRow className='screening-row'>
          <IconCheckmark />
          <ScreeningText fontWeight={700}>
            Everything in Essential
          </ScreeningText>
        </ScreeningRow>
        <ScreeningRow className='screening-row'>
          <IconCheckmark />
          <ScreeningText className='screening-text'>
            Federal criminal search (10-year)
          </ScreeningText>
          <Tooltip
            align='right-top'
            label={t(`pages.packagesPage.tooltips.federalCriminal`)}
          >
            <InformationIcon icon='Information' size='20px' />
          </Tooltip>
        </ScreeningRow>
        <ScreeningRow className='screening-row'>
          <IconCheckmark />
          <ScreeningText className='screening-text'>
            State criminal search (7-year)
          </ScreeningText>
          <Tooltip
            align='right-top'
            label={t(`pages.packagesPage.tooltips.stateCriminal`)}
          >
            <InformationIcon icon='Information' size='20px' />
          </Tooltip>
        </ScreeningRow>
      </>
    </PackageCard>
  );
};

export default CompletePackageCard;
