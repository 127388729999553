import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { Geo } from '@dashboard-experience/utils';
import { GeosDropdown, ProgramsDropdown } from '../Dropdowns';
import { GetStartedT } from '../..';

type Props = {
  geos: Geo[];
  selectedGeo: Geo;
  setSelectedGeo: (geo: Geo) => void;
  programs: GetStartedT.Program[];
  selectedProgram: GetStartedT.Program | null;
  setSelectedProgram: Dispatch<SetStateAction<GetStartedT.Program | null>>;
  geos_required: boolean;
  programs_enabled: boolean;
};

const GeosAndProgramsSection: React.FC<Props> = ({
  geos,
  selectedGeo,
  setSelectedGeo,
  programs,
  selectedProgram,
  setSelectedProgram,
  geos_required,
  programs_enabled,
}) => {
  const selectablePrograms = useMemo(() => {
    if (selectedGeo?.id && selectedGeo?.id !== 'None') {
      return programs.filter(program => {
        return program.geo_ids.includes(selectedGeo?.id);
      });
    }
    return [];
  }, [programs, selectedGeo?.id]);

  const showProgramsDropdown =
    programs_enabled && selectablePrograms.length > 0;

  return (
    <div data-testid='geos-and-programs-section'>
      {geos.length > 0 && (
        <GeosDropdown
          geos={geos}
          selectedGeo={selectedGeo}
          selectedProgram={selectedProgram}
          setSelectedGeo={setSelectedGeo}
          setSelectedProgram={setSelectedProgram}
          geos_required={geos_required}
        />
      )}
      {showProgramsDropdown && (
        <ProgramsDropdown
          programs={programs}
          selectedProgram={selectedProgram}
          setSelectedProgram={setSelectedProgram}
          selectedGeo={selectedGeo}
        />
      )}
    </div>
  );
};

export default GeosAndProgramsSection;
