import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { CSV_UPLOAD_BULK_ORDER_WITH_SMS_CSV_TEMPLATE } from 'Constants';
import { namespace } from '../../locales';

const CandidateBulkUpload = () => {
  const { t } = useTranslation();
  const basePath = `${namespace}:candidateContactInfo.upload`;

  const csvTemplate = CSV_UPLOAD_BULK_ORDER_WITH_SMS_CSV_TEMPLATE;

  const handleDownloadCSVTemplate = useCallback(() => {
    // TODO: tracking event fo download CSV
  }, []);

  const handleRedirectToBulkOrderHistory = useCallback(e => {
    e.preventDefault();
    // TODO: redirect to bulk order history
  }, []);

  return (
    <>
      <p className='p2'>
        {t(`${basePath}.needHelp`)}{' '}
        <M.Link
          size='lg'
          data-testid='download-csv-template'
          download
          onClick={handleDownloadCSVTemplate}
          href={csvTemplate}
        >
          {t(`${basePath}.downloadTemplate`)}
        </M.Link>
      </p>

      <h5>{t(`${basePath}.templateInstructions.header`)}</h5>
      <ul>
        <li>&bull; {t(`${basePath}.templateInstructions.stepOne`)}</li>
        <li>&bull; {t(`${basePath}.templateInstructions.stepTwo`)}</li>
      </ul>

      <p className='p2'>
        {t(`${basePath}.findRecordInstructions.findARecord`)}{' '}
        <span className='bold'>
          {t(`${basePath}.findRecordInstructions.paymentAndBilling`)}
        </span>{' '}
        &gt;{' '}
        <M.Link
          className='bold'
          size='lg'
          data-testid='redirect-to-bulk-history'
          href='#'
          onClick={handleRedirectToBulkOrderHistory}
        >
          {t(`${basePath}.findRecordInstructions.bulkOrderHistory`)}
        </M.Link>
      </p>
    </>
  );
};

export default CandidateBulkUpload;
