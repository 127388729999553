import moment from 'moment';
import { Exception } from 'types';

export const STATE_DOCUMENT_TYPES = {
  criminal_screening_requirement: {
    'maiden name': {
      documentType: 'Mother’s Maiden Name',
    },
    gender: {
      documentType: 'Gender',
    },
    'additional authorization': {
      documentType: 'Additional Authorization',
    },
    'address in the Mariana Islands': {
      documentType: 'Address in the Mariana Islands',
    },
    'place of birth': {
      documentType: 'Place of Birth',
    },
    'government id': {
      documentType: 'Government ID',
    },
    'current address': {
      documentType: 'Current Address',
    },
  },
};

export const RESOLVABLE_EXCEPTION_DOCUMENT_TYPES = {
  ssn_confirmation_requested: {
    documentType: 'Social Security Number',
  },
  ssn_documentation_requested: {
    documentType: 'Social Security Card',
  },
  id_documentation_requested: {
    documentType: 'ID Card',
  },
  employment_documentation_requested: {
    documentType: 'Employment History',
  },
  education_documentation_requested: {
    documentType: 'Education History',
  },
  dl_documentation_requested: {
    documentType: 'Driver’s License',
  },
  previous_dl_documentation_requested: {
    documentType: 'Current or Previous Driver’s License',
  },
  driving_experience: {
    documentType: 'Additional Previous Licensing Information',
  },
  mvr_consent: {
    documentType: 'Driver’s License and Provide Additional Consent',
  },
  occupational_health_missing_paperwork: {
    documentType: 'Health Screening Documentation',
  },
  international_documentation_requested: {
    documentType: 'International Search Documentation',
  },
  criminal_screening_requirement: {
    documentType: 'State Search Documentation',
  },
};

const getStateDocumentTypes = (exception: Exception): string | undefined => {
  const comment = exception?.comment || '';
  const docTypes = STATE_DOCUMENT_TYPES.criminal_screening_requirement;
  let documentTypes;

  const matchedDocumentTypes = Object.keys(docTypes)
    .filter(key => comment.includes(key))
    .map(key => docTypes[key as keyof typeof docTypes].documentType);

  if (matchedDocumentTypes.length) {
    const lastDocumentType = matchedDocumentTypes.pop()!;

    if (matchedDocumentTypes.length > 1) {
      return `${matchedDocumentTypes.join(', ')}, and ${lastDocumentType}`;
    }
    if (matchedDocumentTypes.length === 1) {
      return `${matchedDocumentTypes[0]} and ${lastDocumentType}`;
    }
    documentTypes = lastDocumentType;
  }
  return documentTypes;
};

const getInternationalDocumentTypes = (
  exception: Exception,
): string | undefined => {
  const comment = exception.comment || '';
  const marker = 'provide the following:';
  const markerIndex = comment.indexOf(marker);
  let documentTypes;

  if (markerIndex !== -1) {
    let extractedText = comment.slice(markerIndex + marker.length).trim();

    // Remove trailing period or space
    extractedText = extractedText.replace(/\.\s*$/, '');

    documentTypes = extractedText;
  }

  return documentTypes;
};

export const isResolvableExceptionType = (exception: Exception): boolean => {
  if (exception.type === 'international_documentation_requested') {
    return exception.screening_type !== 'MatrixInternationalCriminalSearch';
  }

  return exception.type in RESOLVABLE_EXCEPTION_DOCUMENT_TYPES;
};

export const getExceptionDocumentTypes = (
  exception: Exception,
): string | undefined => {
  let documentTypes;

  if (exception.type === 'international_documentation_requested') {
    documentTypes = getInternationalDocumentTypes(exception);
  }

  if (exception.type === 'criminal_screening_requirement') {
    documentTypes = getStateDocumentTypes(exception);
  }

  if (!documentTypes && isResolvableExceptionType(exception)) {
    documentTypes =
      RESOLVABLE_EXCEPTION_DOCUMENT_TYPES[
        exception.type as keyof typeof RESOLVABLE_EXCEPTION_DOCUMENT_TYPES
      ].documentType;
  }

  return documentTypes;
};

export const getOldestExceptionCreationDate = (
  exceptions: Exception[],
): string => {
  const sortedExceptions = exceptions.slice().sort((a, b) => {
    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  });

  const oldestException = sortedExceptions[0];
  return moment(oldestException.created_at).format('MMM D, YYYY h:mm A [PT]');
};

export const getExpirationDaysMessage = (exceptions: Exception[]): string => {
  let maxExpirationDays = 0;

  for (const exception of exceptions) {
    const createdAt = moment(exception.created_at);
    // Handle edge case where expires_at is null: default to 7 days after the creation date.
    // Assumes the exception is resolvable, as the type has been checked in the ResolveExceptionsBanners component.
    const defaultExpiresAt = createdAt.add(7, 'days');
    const expiresAt = moment(exception.expires_at || defaultExpiresAt);

    if (createdAt.isValid() && expiresAt.isValid()) {
      const differenceInDays = expiresAt.diff(moment(), 'days');

      if (differenceInDays > maxExpirationDays) {
        maxExpirationDays = differenceInDays;
      }
    }
  }
  switch (maxExpirationDays) {
    case 0:
      return 'by today';
    case 1:
      return 'within 1 day';
    default:
      return `within ${maxExpirationDays} days`;
  }
};
