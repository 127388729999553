import React, { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  CANDIDATE_REPORT_EVENT_NAMES,
  scrollToTop,
  useTrackEvent,
} from 'utils';
import { namespace } from 'modules/adjudication/locales';
import { useTranslation } from 'react-i18next';
import { usePauseAdverseAction } from 'modules/adjudication/api';
import { usePauseAdverseActionAllowed } from 'modules/adjudication/utilities';
import { M } from '@dashboard-experience/mastodon';
import { useUser } from '../../../../../../context/CurrentUser';

const container = (document.getElementById('mastodon') ||
  document.body) as Element;

const usePauseAction = () => {
  const currentUser = useUser();
  const trackEvent = useTrackEvent();
  const displayPauseAdverseAction = usePauseAdverseActionAllowed();
  const { call } = usePauseAdverseAction();
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [isSigned, setIsSigned] = useState(false);

  const header = t(`${namespace}:report.actions.pause.modal.header`);
  const confirm = t(`${namespace}:report.actions.pause.modal.continue`);
  const cancel = t(`${namespace}:report.actions.pause.modal.cancel`);
  const title = t(`${namespace}:report.actions.pause.action`);
  const subtitle = t(`${namespace}:report.actions.pause.description`);
  const message1 = t(`${namespace}:report.actions.pause.modal.msg_1`);
  const message2 = t(`${namespace}:report.actions.pause.modal.msg_2`);
  const signed = t(`${namespace}:report.actions.pause.modal.signed`, {
    nameOrEmail: currentUser.full_name || currentUser.email,
  });

  const action = useCallback(() => {
    setIsSigned(false);
    setOpenModal(true);
    trackEvent(
      CANDIDATE_REPORT_EVENT_NAMES.CUSTOMER_SELECTED_CANDIDATE_ACTION,
      { Action: 'Pause Adverse Action' },
    );
    scrollToTop();
  }, [trackEvent]);

  const hideConfirmModal = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const handleConfirm = useCallback(async () => {
    await call();
    hideConfirmModal();
  }, [call, hideConfirmModal]);

  const toggleSigned = useCallback(() => {
    setIsSigned((prevCheck) => !prevCheck);
  }, []);

  const modal = openModal
    ? createPortal(
        <M.ComposedModal
          onClose={hideConfirmModal}
          open={openModal}
          className='actions-modal'
        >
          <M.ModalHeader closeModal={hideConfirmModal}>
            <h2>{header}</h2>
          </M.ModalHeader>
          <M.ModalBody>
            <p>{message1}</p>
            <p style={{ marginBottom: '1rem' }}>{message2}</p>
            <M.Checkbox
              labelText={signed}
              id='signed_by'
              onChange={toggleSigned}
            />
          </M.ModalBody>
          <M.ModalFooter>
            <M.BinaryButtons
              btnLeft={{ name: cancel, onClick: hideConfirmModal }}
              btnRight={{
                name: confirm,
                onClick: handleConfirm,
                disabled: !isSigned,
              }}
              size='sm'
            />
          </M.ModalFooter>
        </M.ComposedModal>,
        container,
      )
    : null;

  return displayPauseAdverseAction
    ? {
        title,
        /* eslint-disable-next-line react/no-danger */
        subtitle: <div dangerouslySetInnerHTML={{ __html: subtitle }} />,
        action,
        modal,
      }
    : null;
};

export default usePauseAction;
