import React, { useCallback } from 'react';
import {
  City,
  MAJOR_CITIES,
} from 'components/Packages/OrderBackgroundCheck/GetStarted/MajorCities';
import { GetStartedT } from 'components/BetterOrderExperience';
import { CityDropdown } from './Dropdown.styles';

const cityItemToString = (city: City) =>
  city ? `${city.name}, ${city.state}` : '';

type Props = {
  selectedLocation: GetStartedT.WorkLocation;
  setSelectedLocation: (location: GetStartedT.WorkLocation) => void;
};
const SelectCity: React.FC<Props> = ({
  selectedLocation,
  setSelectedLocation,
}) => {
  const selectedCity = selectedLocation.city
    ? {
        name: selectedLocation.city,
        state: selectedLocation.state,
      }
    : '';

  const selectableCities = MAJOR_CITIES.filter(
    (c: City) => c.state === selectedLocation?.state,
  );

  const handleCityChange = useCallback(
    ({ selectedItem }) => {
      setSelectedLocation({
        ...selectedLocation,
        city: selectedItem?.name,
      });
    },
    [setSelectedLocation, selectedLocation],
  );

  return (
    <CityDropdown
      data-testid='city-dropdown'
      id='city-dropdown'
      titleText=''
      label='City (Optional)'
      items={selectableCities}
      itemToString={cityItemToString}
      onChange={handleCityChange}
      selectedItem={selectedCity}
    />
  );
};

export default SelectCity;
